import { useCallback } from 'react';
import { updatePayersPerson } from 'services/booking/bookingService';
import { useRefreshEditor } from 'hooks/bookingEditor/useRefreshEditor';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import { RouterPaths } from 'app/router/RouterPaths';
import { useTranslations } from 'components/shared/i18n';
import { useNavigate } from 'react-router-dom';
import { ServiceResultType } from 'services/types/ServiceResultTypes';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';
import { PaymentFields, PaymentValues } from 'components/flexFlow/payment/PaymentTypes';
import { EHI_DOMAINS, generateReferenceUrn, parseUrn } from 'utils/urnUtils';
import { getAppConfigCache } from 'services/appConfig/appConfigService';

export type SavePayment = {
  save: (data: PaymentValues) => Promise<ServiceResultType>;
};

export const useSavePayment = (): SavePayment => {
  const { t } = useTranslations();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const { refreshEditor } = useRefreshEditor();
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();

  const handleErrors = useCallback(
    (errors: ResponseMessage[]) => {
      showAlert({
        title: t('error.error'),
        description: t('error.editorError', {
          error: errors?.[0].localizedMessage || '',
        }),
        primaryActionText: t('error.reload'),
        secondaryActionText: t('error.home'),
        open: true,
        onPrimaryAction: () => {
          return refreshEditor(bookingEditorId);
        },
        onSecondaryAction: () => {
          navigate(RouterPaths.Search);
        },
        onClose: () => {
          return;
        },
      });
    },
    [bookingEditorId, navigate, refreshEditor, showAlert, t]
  );

  const save = useCallback(
    async (values: PaymentValues) => {
      const paymentValue = values[PaymentFields.PaymentType];
      const appConfig = getAppConfigCache();
      const defaultEhiDatabase = appConfig?.defaultEhiDatabase ?? '';
      const payload = {
        paymentMethod: generateReferenceUrn(
          EHI_DOMAINS.rental.name,
          EHI_DOMAINS.rental.booking + ':' + EHI_DOMAINS.rental.person + ':' + EHI_DOMAINS.rental.paymentMethod,
          parseUrn(paymentValue.key),
          defaultEhiDatabase
        ),
        cardProvided: false,
      };

      const { errors } = await updateAndRefresh(async () => {
        await updatePayersPerson(bookingEditorId, payload);
      });

      if (errors) {
        handleErrors(errors);
      }
      return { success: !!errors, errors: errors };
    },
    [bookingEditorId, handleErrors, updateAndRefresh]
  );

  return { save };
};
