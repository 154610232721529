import { Body1 } from '@ehi/ui';
import { Box } from '@mui/material';
import { useTranslations } from 'components/shared/i18n';

export const VehicleShopSection = () => {
  const { t } = useTranslations();
  return (
    <Box>
      <Body1 bold>{t('rateAndBilling.vehicleShop')}</Body1>
    </Box>
  );
};
