import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslations } from 'components/shared/i18n';
import { BranchLookupFields, SearchByType } from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupTypes';
import { BranchLookupRadioGroup } from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupRadioGroup';
import { CountrySearchDropDown } from 'components/flexFlow/whenAndWhere/branchLookup/CountrySearchDropDown';
import { GroupDropDown } from 'components/flexFlow/whenAndWhere/branchLookup/GroupDropDown';
import { EhiButton } from '@ehi/ui';
import {
  StyledBoxDropDownContainer,
  StyledBranchLookUpSearchBox,
  StyledButtonContainer,
  StyledSearchInput,
} from './BranchLookup.styles';
import { InputIconButton } from 'components/shared/ui/InputIconButton/InputIconButton';
import SearchIcon from '@mui/icons-material/Search';
import { FieldClearIcon } from 'components/shared/ui/FieldClearIcon';

type BranchLookupSearchProps = {
  onSearch: () => void;
  handleReset: () => void;
};

export const BranchLookupSearch: FC<BranchLookupSearchProps> = ({ onSearch, handleReset }) => {
  const { t } = useTranslations();
  const { resetField, watch } = useFormContext();

  const [searchInput, searchType] = watch([BranchLookupFields.SearchInputValue, BranchLookupFields.SearchBy]);

  return (
    <StyledBranchLookUpSearchBox>
      <BranchLookupRadioGroup />
      <StyledSearchInput
        name={BranchLookupFields.SearchInputValue}
        data-testid={BranchLookupFields.SearchInputValue}
        placeholder={
          searchType === SearchByType.PostalCode ? t('location.postalCode') : t('whenWhere.locationSearchLabel')
        }
        InputProps={{
          startAdornment: <InputIconButton position={'start'} icon={<SearchIcon />} />,
          endAdornment: !!searchInput?.length && (
            <InputIconButton
              data-testid={BranchLookupFields.SearchInputValue.concat('ClearIcon')}
              icon={<FieldClearIcon />}
              label={t('common.clear')}
              onClick={() => resetField(BranchLookupFields.SearchInputValue)}
            />
          ),
        }}
      />
      <StyledBoxDropDownContainer>
        <CountrySearchDropDown />
        <GroupDropDown />
        <StyledButtonContainer>
          <EhiButton data-testid={'branchLookupReset'} onClick={handleReset}>
            {t('common.reset')}
          </EhiButton>
          <EhiButton
            primary
            name={'branchLookupSearch'}
            data-testid={'branchLookupSearch'}
            onClick={onSearch}
            disabled={!searchInput}>
            {t('common.search')}
          </EhiButton>
        </StyledButtonContainer>
      </StyledBoxDropDownContainer>
    </StyledBranchLookUpSearchBox>
  );
};
