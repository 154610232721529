import { useCallback } from 'react';
import { Location } from 'services/location/locationRollupTypes';
import { useLocale } from 'components/shared/i18n';
import { updateDefaultAttributes } from 'components/shared/logger/splunkLogger';
import { useLdProvider } from 'components/shared/launchDarkly/LaunchDarklyService';
import { initLDFeatureFlags, selectOverriddenFeatureFlags } from 'redux/slices/featureFlags/featureFlagsSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { convertLdFeatureFlags } from 'utils/featureFlagUtils';
import { useLocationRollUpService } from 'services/location/useLocationRollUpService';
import { EhiCounter } from '@ehi/location';
import { FeatureFlagState } from 'redux/slices/featureFlags/FeatureFlagsSliceTypes';
import { EhiErrors } from 'services/types/EhiErrorsTypes';

export type EhiLocationHook = {
  doFetchLocation: (locationId: string) => Promise<{
    location: Location;
    peopleSoftId: string;
    stationId: string;
    groupBranch: string;
    counters: EhiCounter[] | undefined;
  }>;
  validateLocation: (location: Location) => Promise<boolean>;
};
export const useEhiLocation = (): EhiLocationHook => {
  const { initLocaleForLocation } = useLocale();
  const { identifyUser } = useLdProvider();
  const { locationFeatureFlagEnabled } = useAppSelector(selectOverriddenFeatureFlags);
  const dispatch = useAppDispatch();
  const overriddenFlags = useAppSelector(selectOverriddenFeatureFlags);
  const { queryLocation } = useLocationRollUpService();

  const doFetchLocation = useCallback(
    async (locationId: string) => {
      try {
        const locationRollup = await queryLocation(locationId);
        return {
          location: locationRollup,
          peopleSoftId: locationRollup?.location.id ?? '',
          stationId: locationRollup?.location.stationId ?? '',
          groupBranch: locationRollup?.location.groupBranch ?? '',
          counters: locationRollup?.location.counters,
        };
      } catch (e) {
        throw Error((e as EhiErrors)?.errors?.[0]?.localizedMessage);
      }
    },
    [queryLocation]
  );

  // Note: update validation rules with https://enterprisemobility.atlassian.net/browse/GRES-741
  const validateLocation = useCallback(
    async (location: Location) => {
      const newUserFlags = await identifyUser({
        groupBranchId: location.location.groupBranch,
        peoplesoftId: location.location.id,
        countryCode: location.location.locale.iso3Country,
      });

      if (locationFeatureFlagEnabled !== FeatureFlagState.ENABLED && newUserFlags !== undefined) {
        if (newUserFlags['rpu-location-enabled'] === false) {
          return false;
        } else {
          await initLocaleForLocation({
            branchIso2Language: location.location.locale.iso2Language,
            branchIso2Country: location.location.locale.iso2Country,
          });
          updateDefaultAttributes();
          dispatch(
            initLDFeatureFlags({
              ldFlags: convertLdFeatureFlags(newUserFlags),
              overriddenFlags: overriddenFlags,
            })
          );
          return true;
        }
      } else {
        await initLocaleForLocation({
          branchIso2Language: location.location.locale.iso2Language,
          branchIso2Country: location.location.locale.iso2Country,
        });
        updateDefaultAttributes();
        return true;
      }
    },
    [identifyUser, locationFeatureFlagEnabled, initLocaleForLocation, dispatch, overriddenFlags]
  );
  return { doFetchLocation, validateLocation };
};
