import * as Yup from 'yup';
import { QuickResFields, QuickResValues } from './QuickResTypes';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import { MixedDateTime, combineDateAndTime, compareDate, compareDateTime } from 'utils/dateUtils';
import { ResLocationDetails } from 'services/location/locationRollupTypes';

export const EMPTY_VALUE = '';

export const quickResValidationSchema = (t: TFunction<'translation'>) =>
  Yup.object().shape({
    lastName: Yup.string()
      .required(t('validation.requiredField'))
      .min(2, t('validation.minFieldLength'))
      .matches(/^(\D*)$/i, t('validation.invalidLastName')),
    firstName: Yup.string()
      .matches(/^(\D*)$/i, t('validation.invalidFirstName'))
      .nullable()
      .test('length', t('validation.minFieldLength'), (val) => (val && val.length >= 2) || !val),
    age: Yup.string().nullable(),
    phone: Yup.string()
      .matches(/^[0-9]*$/, t('validation.positiveNumber'))
      .test('length', t('validation.phoneNumberValidation'), (val) => (val && val.length == 10) || !val)
      .nullable(),
    email: Yup.string().email(t('validation.invalidEmail')).nullable(),
    startDate: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('validation.invalidDateFormat'))
      .test(QuickResFields.StartDate, t('validation.startDateRequired'), function (value, context) {
        const { startTime, returnDate, returnTime } = context.parent;
        return !value && (startTime || returnDate || returnTime) ? false : true;
      })
      .test(QuickResFields.StartDate, t('validation.currentOrFutureDate'), function (value, context) {
        const { currentLocationTime } = context.parent;
        if (value && currentLocationTime) {
          return compareDate(DateTime.fromJSDate(value), currentLocationTime);
        } else {
          return true;
        }
      })
      .min(DateTime.now().startOf('day').toJSDate(), t('validation.currentOrFutureDate')),
    startTime: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('validation.invalidTimeFormat'))
      .test(QuickResFields.StartTime, t('validation.futureLocationTime'), function (value, context) {
        const { startDate, currentLocationTime } = context.parent;
        if (value && startDate && currentLocationTime) {
          const startDateTime = combineDateAndTime(DateTime.fromJSDate(startDate), DateTime.fromJSDate(value));
          return compareDateTime(startDateTime, currentLocationTime);
        } else {
          return true;
        }
      }),
    returnDate: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('validation.invalidDateFormat'))
      .min(DateTime.now().startOf('day').toJSDate(), t('validation.currentOrFutureDate'))
      .test(QuickResFields.ReturnDate, t('validation.returnDateRequired'), function (value, context) {
        const { returnTime } = context.parent;
        return !value && returnTime ? false : true;
      })
      .test(QuickResFields.ReturnDate, t('validation.returnDateAfterStartDate'), function (value, context) {
        const { startDate } = context.parent;
        if (value && startDate) {
          return compareDate(DateTime.fromJSDate(value), DateTime.fromJSDate(startDate));
        } else {
          return true;
        }
      })
      .test(QuickResFields.ReturnDate, t('validation.currentOrFutureDate'), function (value, context) {
        const { currentLocationTime } = context.parent;
        if (value && currentLocationTime) {
          return compareDate(DateTime.fromJSDate(value), currentLocationTime.startOf('day'));
        } else {
          return true;
        }
      }),
    returnTime: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('validation.invalidTimeFormat'))
      .test(QuickResFields.ReturnTime, t('validation.futurePickupTime'), function (value, context) {
        const { startDate, startTime, returnDate } = context.parent;
        if (value && startDate && startTime && returnDate) {
          const startDateTime = combineDateAndTime(DateTime.fromJSDate(startDate), DateTime.fromJSDate(startTime));
          const returnDateTime = combineDateAndTime(DateTime.fromJSDate(returnDate), DateTime.fromJSDate(value));
          return compareDateTime(returnDateTime, startDateTime);
        } else {
          return true;
        }
      })
      .test(QuickResFields.ReturnTime, t('validation.futurePickupTime'), function (value, context) {
        const { returnDate, currentLocationTime } = context.parent;
        if (returnDate && currentLocationTime && value) {
          const returnDateTime = combineDateAndTime(DateTime.fromJSDate(returnDate), DateTime.fromJSDate(value));
          return compareDateTime(returnDateTime, currentLocationTime);
        } else {
          return true;
        }
      }),
  });

export const quickResInitialValues = (
  currentLocation: ResLocationDetails | undefined,
  getLocalizedDateTime: (timezone: string, dateTime?: MixedDateTime) => DateTime | undefined
): QuickResValues => ({
  [QuickResFields.CurrentLocationTimezone]: currentLocation?.timezone ?? 'system',
  [QuickResFields.CurrentLocationUrn]: currentLocation?.urn ?? '',
  [QuickResFields.CurrentLocationTime]: currentLocation?.timezone
    ? (getLocalizedDateTime(currentLocation?.timezone, DateTime.now()) as DateTime)
    : '',
  [QuickResFields.StartDate]: EMPTY_VALUE,
  [QuickResFields.StartTime]: EMPTY_VALUE,
  [QuickResFields.StartDateTime]: EMPTY_VALUE,
  [QuickResFields.ReturnDate]: EMPTY_VALUE,
  [QuickResFields.ReturnTime]: EMPTY_VALUE,
  [QuickResFields.ReturnDateTime]: EMPTY_VALUE,
  [QuickResFields.VehicleClass]: EMPTY_VALUE,
  [QuickResFields.LastName]: EMPTY_VALUE,
  [QuickResFields.FirstName]: EMPTY_VALUE,
  [QuickResFields.Age]: EMPTY_VALUE,
  [QuickResFields.ContactType]: EMPTY_VALUE,
  [QuickResFields.CountryCode]: EMPTY_VALUE,
  [QuickResFields.Phone]: EMPTY_VALUE,
  [QuickResFields.Email]: EMPTY_VALUE,
});

export const AGE_OPTIONS = Array.from({ length: 12 }, (_, index) => index + 18)
  .map((item) => ({
    label: '' + item,
    value: item,
  }))
  .concat([
    {
      label: '30+',
      value: 30,
    },
  ]);

export const ALL_COUNTRIES = [
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:AFG',
    name: 'Afghanistan',
    countryIso2Code: 'AF',
    countryIso3Code: 'AFG',
    currency: {
      code: 'AFA',
      description: 'Afghani',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:ALA',
    name: 'Aland Islands',
    countryIso2Code: 'AX',
    countryIso3Code: 'ALA',
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:ALB',
    name: 'Albania',
    countryIso2Code: 'AL',
    countryIso3Code: 'ALB',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:DZA',
    name: 'Algeria',
    countryIso2Code: 'DZ',
    countryIso3Code: 'DZA',
    currency: {
      code: 'DZD',
      description: 'Algerian Dinar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:ASM',
    name: 'American Samoa',
    countryIso2Code: 'AS',
    countryIso3Code: 'ASM',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'MILES',
      volume: 'GALLONS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:AND',
    name: 'Andorra',
    countryIso2Code: 'AD',
    countryIso3Code: 'AND',
    currency: {
      code: 'ADP',
      description: 'Andorran Peseta',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:AGO',
    name: 'Angola',
    countryIso2Code: 'AO',
    countryIso3Code: 'AGO',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:AIA',
    name: 'Anguilla',
    countryIso2Code: 'AI',
    countryIso3Code: 'AIA',
    currency: {
      code: 'XCD',
      description: 'East Caribbean Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:ATA',
    name: 'Antarctica',
    countryIso2Code: 'AQ',
    countryIso3Code: 'ATA',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:ATG',
    name: 'Antigua And Barbuda',
    countryIso2Code: 'AG',
    countryIso3Code: 'ATG',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:ARG',
    name: 'Argentina',
    countryIso2Code: 'AR',
    countryIso3Code: 'ARG',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:ARM',
    name: 'Armenia',
    countryIso2Code: 'AM',
    countryIso3Code: 'ARM',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:ABW',
    name: 'Aruba',
    countryIso2Code: 'AW',
    countryIso3Code: 'ABW',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:AUS',
    name: 'Australia',
    countryIso2Code: 'AU',
    countryIso3Code: 'AUS',
    currency: {
      code: 'AUD',
      description: 'Australian Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:AUT',
    name: 'Austria',
    countryIso2Code: 'AT',
    countryIso3Code: 'AUT',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:AZE',
    name: 'Azerbaijan',
    countryIso2Code: 'AZ',
    countryIso3Code: 'AZE',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:BHS',
    name: 'Bahamas',
    countryIso2Code: 'BS',
    countryIso3Code: 'BHS',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:BHR',
    name: 'Bahrain',
    countryIso2Code: 'BH',
    countryIso3Code: 'BHR',
    currency: {
      code: 'BHD',
      description: 'Bahraini Dinar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:BGD',
    name: 'Bangladesh',
    countryIso2Code: 'BD',
    countryIso3Code: 'BGD',
    currency: {
      code: 'BDT',
      description: 'Taka',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:BRB',
    name: 'Barbados',
    countryIso2Code: 'BB',
    countryIso3Code: 'BRB',
    currency: {
      code: 'BBD',
      description: 'Barbados Dollar',
    },
    unitsOfMeasure: {
      distance: 'MILES',
      volume: 'GALLONS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:BLR',
    name: 'Belarus',
    countryIso2Code: 'BY',
    countryIso3Code: 'BLR',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:BEL',
    name: 'Belgium',
    countryIso2Code: 'BE',
    countryIso3Code: 'BEL',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:BLZ',
    name: 'Belize',
    countryIso2Code: 'BZ',
    countryIso3Code: 'BLZ',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:BEN',
    name: 'Benin',
    countryIso2Code: 'BJ',
    countryIso3Code: 'BEN',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:BMU',
    name: 'Bermuda',
    countryIso2Code: 'BM',
    countryIso3Code: 'BMU',
    currency: {
      code: 'BMD',
      description: '(customarily known as',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:BTN',
    name: 'Bhutan',
    countryIso2Code: 'BT',
    countryIso3Code: 'BTN',
    currency: {
      code: 'BTN',
      description: 'Ngultrum',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:BOL',
    name: 'Bolivia',
    countryIso2Code: 'BO',
    countryIso3Code: 'BOL',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:BES',
    name: 'Bonaire, Sint Eustatius and Saba',
    countryIso2Code: 'BQ',
    countryIso3Code: 'BES',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:BIH',
    name: 'Bosnia And Herzegovina',
    countryIso2Code: 'BA',
    countryIso3Code: 'BIH',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:BWA',
    name: 'Botswana',
    countryIso2Code: 'BW',
    countryIso3Code: 'BWA',
    currency: {
      code: 'BWP',
      description: 'Pula',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:BVT',
    name: 'Bouvet Island',
    countryIso2Code: 'BV',
    countryIso3Code: 'BVT',
    currency: {
      code: 'NOK',
      description: 'Norwegian Krone',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:BRA',
    name: 'Brazil',
    countryIso2Code: 'BR',
    countryIso3Code: 'BRA',
    currency: {
      code: 'BRL',
      description: 'Brazilian Real',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:IOT',
    name: 'British Indian Ocean Territory',
    countryIso2Code: 'IO',
    countryIso3Code: 'IOT',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:BRN',
    name: 'Brunei Darussalam',
    countryIso2Code: 'BN',
    countryIso3Code: 'BRN',
    currency: {
      code: 'BND',
      description: 'Brunei Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:BGR',
    name: 'Bulgaria',
    countryIso2Code: 'BG',
    countryIso3Code: 'BGR',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:BFA',
    name: 'Burkina Faso',
    countryIso2Code: 'BF',
    countryIso3Code: 'BFA',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:BDI',
    name: 'Burundi',
    countryIso2Code: 'BI',
    countryIso3Code: 'BDI',
    currency: {
      code: 'BIF',
      description: 'Burundi Franc',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:KHM',
    name: 'Cambodia',
    countryIso2Code: 'KH',
    countryIso3Code: 'KHM',
    currency: {
      code: 'KHR',
      description: 'Riel',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:CMR',
    name: 'Cameroon',
    countryIso2Code: 'CM',
    countryIso3Code: 'CMR',
    currency: {
      code: 'XAF',
      description: 'CFA Franc BEAC ',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:CAN',
    name: 'Canada',
    countryIso2Code: 'CA',
    countryIso3Code: 'CAN',
    currency: {
      code: 'CAD',
      description: 'Canadian Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:CPV',
    name: 'Cape Verde',
    countryIso2Code: 'CV',
    countryIso3Code: 'CPV',
    currency: {
      code: 'CVE',
      description: 'Cape Verde Escudo',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:CYM',
    name: 'Cayman Islands',
    countryIso2Code: 'KY',
    countryIso3Code: 'CYM',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:CAF',
    name: 'Central African Republic',
    countryIso2Code: 'CF',
    countryIso3Code: 'CAF',
    currency: {
      code: 'XAF',
      description: 'CFA Franc BEAC ',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:TCD',
    name: 'Chad',
    countryIso2Code: 'TD',
    countryIso3Code: 'TCD',
    currency: {
      code: 'XAF',
      description: 'CFA Franc BEAC ',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:CHL',
    name: 'Chile',
    countryIso2Code: 'CL',
    countryIso3Code: 'CHL',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:CHN',
    name: 'China',
    countryIso2Code: 'CN',
    countryIso3Code: 'CHN',
    currency: {
      code: 'CNY',
      description: 'Yuan Renminbi',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:CXR',
    name: 'Christmas Island',
    countryIso2Code: 'CX',
    countryIso3Code: 'CXR',
    currency: {
      code: 'AUD',
      description: 'Australian Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:CCK',
    name: 'Cocos (Keeling) Islands',
    countryIso2Code: 'CC',
    countryIso3Code: 'CCK',
    currency: {
      code: 'AUD',
      description: 'Australian Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:COL',
    name: 'Colombia',
    countryIso2Code: 'CO',
    countryIso3Code: 'COL',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:COM',
    name: 'Comoros',
    countryIso2Code: 'KM',
    countryIso3Code: 'COM',
    currency: {
      code: 'KMF',
      description: 'Comoro Franc',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:COG',
    name: 'Congo',
    countryIso2Code: 'CG',
    countryIso3Code: 'COG',
    currency: {
      code: 'XAF',
      description: 'CFA Franc BEAC ',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:COD',
    name: 'Congo, The Democratic Republic Of The',
    countryIso2Code: 'CD',
    countryIso3Code: 'COD',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:COK',
    name: 'Cook Islands',
    countryIso2Code: 'CK',
    countryIso3Code: 'COK',
    currency: {
      code: 'NZD',
      description: 'New Zealand Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:CRI',
    name: 'Costa Rica',
    countryIso2Code: 'CR',
    countryIso3Code: 'CRI',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:CIV',
    name: 'Côte DIvoire',
    countryIso2Code: 'CI',
    countryIso3Code: 'CIV',
    currency: {
      code: 'XOF',
      description: 'CFA Franc BCEAO ',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:HRV',
    name: 'Croatia',
    countryIso2Code: 'HR',
    countryIso3Code: 'HRV',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:CUB',
    name: 'Cuba',
    countryIso2Code: 'CU',
    countryIso3Code: 'CUB',
    currency: {
      code: 'CUP',
      description: 'Cuban Peso',
    },
    unitsOfMeasure: {
      distance: 'MILES',
      volume: 'GALLONS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:CUW',
    name: 'Curacao',
    countryIso2Code: 'CW',
    countryIso3Code: 'CUW',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:CYP',
    name: 'Cyprus',
    countryIso2Code: 'CY',
    countryIso3Code: 'CYP',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:CZE',
    name: 'Czech Republic',
    countryIso2Code: 'CZ',
    countryIso3Code: 'CZE',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:DNK',
    name: 'Denmark',
    countryIso2Code: 'DK',
    countryIso3Code: 'DNK',
    currency: {
      code: 'DKK',
      description: 'Danish Krone',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:DJI',
    name: 'Djibouti',
    countryIso2Code: 'DJ',
    countryIso3Code: 'DJI',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'MILES',
      volume: 'GALLONS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:DMA',
    name: 'Dominica',
    countryIso2Code: 'DM',
    countryIso3Code: 'DMA',
    currency: {
      code: 'XCD',
      description: 'East Caribbean Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:DOM',
    name: 'Dominican Republic',
    countryIso2Code: 'DO',
    countryIso3Code: 'DOM',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:ECU',
    name: 'Ecuador',
    countryIso2Code: 'EC',
    countryIso3Code: 'ECU',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'GALLONS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:EGY',
    name: 'Egypt',
    countryIso2Code: 'EG',
    countryIso3Code: 'EGY',
    currency: {
      code: 'EGP',
      description: 'Egyptian Pound',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:SLV',
    name: 'El Salvador',
    countryIso2Code: 'SV',
    countryIso3Code: 'SLV',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:GNQ',
    name: 'Equatorial Guinea',
    countryIso2Code: 'GQ',
    countryIso3Code: 'GNQ',
    currency: {
      code: 'XAF',
      description: 'CFA Franc BEAC ',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:ERI',
    name: 'Eritrea',
    countryIso2Code: 'ER',
    countryIso3Code: 'ERI',
    currency: {
      code: 'ETB',
      description: 'Ethiopian Birr',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:EST',
    name: 'Estonia',
    countryIso2Code: 'EE',
    countryIso3Code: 'EST',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:ETH',
    name: 'Ethiopia',
    countryIso2Code: 'ET',
    countryIso3Code: 'ETH',
    currency: {
      code: 'ETB',
      description: 'Ethiopian Birr',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:FLK',
    name: 'Falkland Islands (Malvinas)',
    countryIso2Code: 'FK',
    countryIso3Code: 'FLK',
    currency: {
      code: 'FKP',
      description: 'Falkland Islands Pound',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:FRO',
    name: 'Faroe Islands',
    countryIso2Code: 'FO',
    countryIso3Code: 'FRO',
    currency: {
      code: 'DKK',
      description: 'Danish Krone',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:FJI',
    name: 'Fiji',
    countryIso2Code: 'FJ',
    countryIso3Code: 'FJI',
    currency: {
      code: 'FJD',
      description: 'Fiji Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:FIN',
    name: 'Finland',
    countryIso2Code: 'FI',
    countryIso3Code: 'FIN',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:FRA',
    name: 'France',
    countryIso2Code: 'FR',
    countryIso3Code: 'FRA',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:GUF',
    name: 'French Guiana',
    countryIso2Code: 'GF',
    countryIso3Code: 'GUF',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:PYF',
    name: 'French Polynesia',
    countryIso2Code: 'PF',
    countryIso3Code: 'PYF',
    currency: {
      code: 'XPF',
      description: 'CFP Franc',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:ATF',
    name: 'French Southern Territories',
    countryIso2Code: 'TF',
    countryIso3Code: 'ATF',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:GAB',
    name: 'Gabon',
    countryIso2Code: 'GA',
    countryIso3Code: 'GAB',
    currency: {
      code: 'XAF',
      description: 'CFA Franc BEAC ',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:GMB',
    name: 'Gambia',
    countryIso2Code: 'GM',
    countryIso3Code: 'GMB',
    currency: {
      code: 'GMD',
      description: 'Dalasi',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:GEO',
    name: 'Georgia',
    countryIso2Code: 'GE',
    countryIso3Code: 'GEO',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:DEU',
    name: 'Germany',
    countryIso2Code: 'DE',
    countryIso3Code: 'DEU',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:GHA',
    name: 'Ghana',
    countryIso2Code: 'GH',
    countryIso3Code: 'GHA',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:GIB',
    name: 'Gibraltar',
    countryIso2Code: 'GI',
    countryIso3Code: 'GIB',
    currency: {
      code: 'GIP',
      description: 'Gibraltar Pound',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:GRC',
    name: 'Greece',
    countryIso2Code: 'GR',
    countryIso3Code: 'GRC',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:GRL',
    name: 'Greenland',
    countryIso2Code: 'GL',
    countryIso3Code: 'GRL',
    currency: {
      code: 'DKK',
      description: 'Danish Krone',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:GRD',
    name: 'Grenada',
    countryIso2Code: 'GD',
    countryIso3Code: 'GRD',
    currency: {
      code: 'XCD',
      description: 'East Caribbean Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:GLP',
    name: 'Guadeloupe',
    countryIso2Code: 'GP',
    countryIso3Code: 'GLP',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:GUM',
    name: 'Guam',
    countryIso2Code: 'GU',
    countryIso3Code: 'GUM',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'MILES',
      volume: 'GALLONS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:GTM',
    name: 'Guatemala',
    countryIso2Code: 'GT',
    countryIso3Code: 'GTM',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:GGY',
    name: 'Guernsey',
    countryIso2Code: 'GG',
    countryIso3Code: 'GGY',
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:GIN',
    name: 'Guinea',
    countryIso2Code: 'GN',
    countryIso3Code: 'GIN',
    currency: {
      code: 'GNF',
      description: 'Guinea Franc',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:GNB',
    name: 'Guinea-Bissau',
    countryIso2Code: 'GW',
    countryIso3Code: 'GNB',
    currency: {
      code: 'GWP',
      description: 'Guinea-Bissau Peso',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:GUY',
    name: 'Guyana',
    countryIso2Code: 'GY',
    countryIso3Code: 'GUY',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:HTI',
    name: 'Haiti',
    countryIso2Code: 'HT',
    countryIso3Code: 'HTI',
    currency: {
      code: 'HTG',
      description: 'Gourde',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:HMD',
    name: 'Heard Island And Mcdonald Islands',
    countryIso2Code: 'HM',
    countryIso3Code: 'HMD',
    currency: {
      code: 'AUD',
      description: 'Australian Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:VAT',
    name: 'Holy See (Vatican City State)',
    countryIso2Code: 'VA',
    countryIso3Code: 'VAT',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:HND',
    name: 'Honduras',
    countryIso2Code: 'HN',
    countryIso3Code: 'HND',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:HKG',
    name: 'Hong Kong',
    countryIso2Code: 'HK',
    countryIso3Code: 'HKG',
    currency: {
      code: 'HKD',
      description: 'Hong Kong Dollar',
    },
    unitsOfMeasure: {
      distance: 'MILES',
      volume: 'GALLONS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:HUN',
    name: 'Hungary',
    countryIso2Code: 'HU',
    countryIso3Code: 'HUN',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:ISL',
    name: 'Iceland',
    countryIso2Code: 'IS',
    countryIso3Code: 'ISL',
    currency: {
      code: 'ISK',
      description: 'Iceland Krona',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:IND',
    name: 'India',
    countryIso2Code: 'IN',
    countryIso3Code: 'IND',
    currency: {
      code: 'INR',
      description: 'Indian Rupee',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:IDN',
    name: 'Indonesia',
    countryIso2Code: 'ID',
    countryIso3Code: 'IDN',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:IRN',
    name: 'Iran',
    countryIso2Code: 'IR',
    countryIso3Code: 'IRN',
    currency: {
      code: 'IRR',
      description: 'Iranian Rial',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:IRQ',
    name: 'Iraq',
    countryIso2Code: 'IQ',
    countryIso3Code: 'IRQ',
    currency: {
      code: 'IQD',
      description: 'Iraqi Dinar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:IRL',
    name: 'Ireland',
    countryIso2Code: 'IE',
    countryIso3Code: 'IRL',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'MILES',
      volume: 'GALLONS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:IMN',
    name: 'Isle of Man',
    countryIso2Code: 'IM',
    countryIso3Code: 'IMN',
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:ISR',
    name: 'Israel',
    countryIso2Code: 'IL',
    countryIso3Code: 'ISR',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:ITA',
    name: 'Italy',
    countryIso2Code: 'IT',
    countryIso3Code: 'ITA',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:JAM',
    name: 'Jamaica',
    countryIso2Code: 'JM',
    countryIso3Code: 'JAM',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'MILES',
      volume: 'GALLONS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:JPN',
    name: 'Japan',
    countryIso2Code: 'JP',
    countryIso3Code: 'JPN',
    currency: {
      code: 'JPY',
      description: 'Yen',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:JEY',
    name: 'Jersey',
    countryIso2Code: 'JE',
    countryIso3Code: 'JEY',
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:JOR',
    name: 'Jordan',
    countryIso2Code: 'JO',
    countryIso3Code: 'JOR',
    currency: {
      code: 'JOD',
      description: 'Jordanian Dinar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:KAZ',
    name: 'Kazakhstan',
    countryIso2Code: 'KZ',
    countryIso3Code: 'KAZ',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:KEN',
    name: 'Kenya',
    countryIso2Code: 'KE',
    countryIso3Code: 'KEN',
    currency: {
      code: 'KES',
      description: 'Kenyan Shilling',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:KIR',
    name: 'Kiribati',
    countryIso2Code: 'KI',
    countryIso3Code: 'KIR',
    currency: {
      code: 'AUD',
      description: 'Australian Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:PRK',
    name: 'Korea, Democratic Peoples Republic Of',
    countryIso2Code: 'KP',
    countryIso3Code: 'PRK',
    currency: {
      code: 'KPW',
      description: 'North Korean Won',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:KOR',
    name: 'Korea, Republic Of',
    countryIso2Code: 'KR',
    countryIso3Code: 'KOR',
    currency: {
      code: 'KRW',
      description: 'Won',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:KWT',
    name: 'Kuwait',
    countryIso2Code: 'KW',
    countryIso3Code: 'KWT',
    currency: {
      code: 'KWD',
      description: 'Kuwaiti Dinar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:KGZ',
    name: 'Kyrgyzstan',
    countryIso2Code: 'KG',
    countryIso3Code: 'KGZ',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:LAO',
    name: 'Lao Peoples Democratic Republic',
    countryIso2Code: 'LA',
    countryIso3Code: 'LAO',
    currency: {
      code: 'LAK',
      description: 'Kip',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:LVA',
    name: 'Latvia',
    countryIso2Code: 'LV',
    countryIso3Code: 'LVA',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:LBN',
    name: 'Lebanon',
    countryIso2Code: 'LB',
    countryIso3Code: 'LBN',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:LSO',
    name: 'Lesotho',
    countryIso2Code: 'LS',
    countryIso3Code: 'LSO',
    currency: {
      code: 'LSL',
      description: 'Loti',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:LBR',
    name: 'Liberia',
    countryIso2Code: 'LR',
    countryIso3Code: 'LBR',
    currency: {
      code: 'LRD',
      description: 'Liberian Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:LBY',
    name: 'Libyan Arab Jamahiriya',
    countryIso2Code: 'LY',
    countryIso3Code: 'LBY',
    currency: {
      code: 'LYD',
      description: 'Libyan Dinar',
    },
    unitsOfMeasure: {
      distance: 'MILES',
      volume: 'GALLONS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:LIE',
    name: 'Liechtenstein',
    countryIso2Code: 'LI',
    countryIso3Code: 'LIE',
    currency: {
      code: 'CHF',
      description: 'Swiss Franc',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:LTU',
    name: 'Lithuania',
    countryIso2Code: 'LT',
    countryIso3Code: 'LTU',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:LUX',
    name: 'Luxembourg',
    countryIso2Code: 'LU',
    countryIso3Code: 'LUX',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:MAC',
    name: 'Macao',
    countryIso2Code: 'MO',
    countryIso3Code: 'MAC',
    currency: {
      code: 'MOP',
      description: 'Pataca',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:MDG',
    name: 'Madagascar',
    countryIso2Code: 'MG',
    countryIso3Code: 'MDG',
    currency: {
      code: 'MGF',
      description: 'Malagasy Franc',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:MWI',
    name: 'Malawi',
    countryIso2Code: 'MW',
    countryIso3Code: 'MWI',
    currency: {
      code: 'MWK',
      description: 'Kwacha',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:MYS',
    name: 'Malaysia',
    countryIso2Code: 'MY',
    countryIso3Code: 'MYS',
    currency: {
      code: 'MYR',
      description: 'Malaysian Ringgit',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:MDV',
    name: 'Maldives',
    countryIso2Code: 'MV',
    countryIso3Code: 'MDV',
    currency: {
      code: 'MVR',
      description: 'Rufiyaa',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:MLI',
    name: 'Mali',
    countryIso2Code: 'ML',
    countryIso3Code: 'MLI',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:MLT',
    name: 'Malta',
    countryIso2Code: 'MT',
    countryIso3Code: 'MLT',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:MHL',
    name: 'Marshall Islands',
    countryIso2Code: 'MH',
    countryIso3Code: 'MHL',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:MTQ',
    name: 'Martinique',
    countryIso2Code: 'MQ',
    countryIso3Code: 'MTQ',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:MRT',
    name: 'Mauritania',
    countryIso2Code: 'MR',
    countryIso3Code: 'MRT',
    currency: {
      code: 'MRO',
      description: 'Ouguiya',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:MUS',
    name: 'Mauritius',
    countryIso2Code: 'MU',
    countryIso3Code: 'MUS',
    currency: {
      code: 'MUR',
      description: 'Mauritius Rupee',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:MYT',
    name: 'Mayotte',
    countryIso2Code: 'YT',
    countryIso3Code: 'MYT',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:MEX',
    name: 'Mexico',
    countryIso2Code: 'MX',
    countryIso3Code: 'MEX',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:FSM',
    name: 'Micronesia, Federated States Of',
    countryIso2Code: 'FM',
    countryIso3Code: 'FSM',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'MILES',
      volume: 'GALLONS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:MDA',
    name: 'Moldova, Republic Of',
    countryIso2Code: 'MD',
    countryIso3Code: 'MDA',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:MCO',
    name: 'Monaco',
    countryIso2Code: 'MC',
    countryIso3Code: 'MCO',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:MNG',
    name: 'Mongolia',
    countryIso2Code: 'MN',
    countryIso3Code: 'MNG',
    currency: {
      code: 'MNT',
      description: 'Tugrik',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:MNE',
    name: 'Montenegro',
    countryIso2Code: 'ME',
    countryIso3Code: 'MNE',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:MSR',
    name: 'Montserrat',
    countryIso2Code: 'MS',
    countryIso3Code: 'MSR',
    currency: {
      code: 'XCD',
      description: 'East Caribbean Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:MAR',
    name: 'Morocco',
    countryIso2Code: 'MA',
    countryIso3Code: 'MAR',
    currency: {
      code: 'MAD',
      description: 'Moroccan Dirham',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:MOZ',
    name: 'Mozambique',
    countryIso2Code: 'MZ',
    countryIso3Code: 'MOZ',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:MMR',
    name: 'Myanmar',
    countryIso2Code: 'MM',
    countryIso3Code: 'MMR',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:NAM',
    name: 'Namibia',
    countryIso2Code: 'NA',
    countryIso3Code: 'NAM',
    currency: {
      code: 'NAD',
      description: 'Namibia Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:NRU',
    name: 'Nauru',
    countryIso2Code: 'NR',
    countryIso3Code: 'NRU',
    currency: {
      code: 'AUD',
      description: 'Australian Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:NPL',
    name: 'Nepal',
    countryIso2Code: 'NP',
    countryIso3Code: 'NPL',
    currency: {
      code: 'NPR',
      description: 'Nepalese Rupee',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:NLD',
    name: 'Netherlands',
    countryIso2Code: 'NL',
    countryIso3Code: 'NLD',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:ANT',
    name: 'Netherlands Antilles',
    countryIso2Code: 'AN',
    countryIso3Code: 'ANT',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:NCL',
    name: 'New Caledonia',
    countryIso2Code: 'NC',
    countryIso3Code: 'NCL',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:NZL',
    name: 'New Zealand',
    countryIso2Code: 'NZ',
    countryIso3Code: 'NZL',
    currency: {
      code: 'NZD',
      description: 'New Zealand Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:NIC',
    name: 'Nicaragua',
    countryIso2Code: 'NI',
    countryIso3Code: 'NIC',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:NER',
    name: 'Niger',
    countryIso2Code: 'NE',
    countryIso3Code: 'NER',
    currency: {
      code: 'XOF',
      description: 'CFA Franc BCEAO ',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:NGA',
    name: 'Nigeria',
    countryIso2Code: 'NG',
    countryIso3Code: 'NGA',
    currency: {
      code: 'NGN',
      description: 'Naira',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:NIU',
    name: 'Niue',
    countryIso2Code: 'NU',
    countryIso3Code: 'NIU',
    currency: {
      code: 'NZD',
      description: 'New Zealand Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:NFK',
    name: 'Norfolk Island',
    countryIso2Code: 'NF',
    countryIso3Code: 'NFK',
    currency: {
      code: 'AUD',
      description: 'Australian Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:MKD',
    name: 'North Macedonia',
    countryIso2Code: 'MK',
    countryIso3Code: 'MKD',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:NOR',
    name: 'Norway',
    countryIso2Code: 'NO',
    countryIso3Code: 'NOR',
    currency: {
      code: 'NOK',
      description: 'Norwegian Krone',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:OMN',
    name: 'Oman',
    countryIso2Code: 'OM',
    countryIso3Code: 'OMN',
    currency: {
      code: 'OMR',
      description: 'Rial Omani',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:PAK',
    name: 'Pakistan',
    countryIso2Code: 'PK',
    countryIso3Code: 'PAK',
    currency: {
      code: 'PKR',
      description: 'Pakistan Rupee',
    },
    unitsOfMeasure: {
      distance: 'MILES',
      volume: 'GALLONS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:PLW',
    name: 'Palau',
    countryIso2Code: 'PW',
    countryIso3Code: 'PLW',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:PSE',
    name: 'Palestinian Territory',
    countryIso2Code: 'PS',
    countryIso3Code: 'PSE',
    currency: {
      code: 'ILS',
      description: 'New Israeli Sheqel***',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'GALLONS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:PAN',
    name: 'Panama',
    countryIso2Code: 'PA',
    countryIso3Code: 'PAN',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:PNG',
    name: 'Papua New Guinea',
    countryIso2Code: 'PG',
    countryIso3Code: 'PNG',
    currency: {
      code: 'PGK',
      description: 'Kina',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:PRY',
    name: 'Paraguay',
    countryIso2Code: 'PY',
    countryIso3Code: 'PRY',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:PER',
    name: 'Peru',
    countryIso2Code: 'PE',
    countryIso3Code: 'PER',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:PHL',
    name: 'Philippines',
    countryIso2Code: 'PH',
    countryIso3Code: 'PHL',
    currency: {
      code: 'PHP',
      description: 'Philippine Peso',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:PCN',
    name: 'Pitcairn',
    countryIso2Code: 'PN',
    countryIso3Code: 'PCN',
    currency: {
      code: 'NZD',
      description: 'New Zealand Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:POL',
    name: 'Poland',
    countryIso2Code: 'PL',
    countryIso3Code: 'POL',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:PRT',
    name: 'Portugal',
    countryIso2Code: 'PT',
    countryIso3Code: 'PRT',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:PRI',
    name: 'Puerto Rico',
    countryIso2Code: 'PR',
    countryIso3Code: 'PRI',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:QAT',
    name: 'Qatar',
    countryIso2Code: 'QA',
    countryIso3Code: 'QAT',
    currency: {
      code: 'QAR',
      description: 'Qatari Rial',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:REU',
    name: 'Réunion',
    countryIso2Code: 'RE',
    countryIso3Code: 'REU',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:ROU',
    name: 'Romania',
    countryIso2Code: 'RO',
    countryIso3Code: 'ROU',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:RUS',
    name: 'Russian Federation',
    countryIso2Code: 'RU',
    countryIso3Code: 'RUS',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:RWA',
    name: 'Rwanda',
    countryIso2Code: 'RW',
    countryIso3Code: 'RWA',
    currency: {
      code: 'RWF',
      description: 'Rwanda Franc',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:BLM',
    name: 'Saint Barthelemy',
    countryIso2Code: 'BL',
    countryIso3Code: 'BLM',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:SHN',
    name: 'Saint Helena',
    countryIso2Code: 'SH',
    countryIso3Code: 'SHN',
    currency: {
      code: 'SHP',
      description: 'Saint Helena Pound',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:KNA',
    name: 'Saint Kitts And Nevis',
    countryIso2Code: 'KN',
    countryIso3Code: 'KNA',
    currency: {
      code: 'XCD',
      description: 'East Caribbean Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:LCA',
    name: 'Saint Lucia',
    countryIso2Code: 'LC',
    countryIso3Code: 'LCA',
    currency: {
      code: 'XCD',
      description: 'East Caribbean Dollar',
    },
    unitsOfMeasure: {
      distance: 'MILES',
      volume: 'GALLONS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:MAF',
    name: 'Saint Martin',
    countryIso2Code: 'MF',
    countryIso3Code: 'MAF',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:SPM',
    name: 'Saint Pierre And Miquelon',
    countryIso2Code: 'PM',
    countryIso3Code: 'SPM',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:VCT',
    name: 'Saint Vincent And The Grenadines',
    countryIso2Code: 'VC',
    countryIso3Code: 'VCT',
    currency: {
      code: 'XCD',
      description: 'East Caribbean Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:MNP',
    name: 'Saipan - Northern Mariana Islands',
    countryIso2Code: 'MP',
    countryIso3Code: 'MNP',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:WSM',
    name: 'Samoa',
    countryIso2Code: 'WS',
    countryIso3Code: 'WSM',
    currency: {
      code: 'WST',
      description: 'Tala',
    },
    unitsOfMeasure: {
      distance: 'MILES',
      volume: 'GALLONS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:SMR',
    name: 'San Marino',
    countryIso2Code: 'SM',
    countryIso3Code: 'SMR',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:STP',
    name: 'Sao Tome And Principe',
    countryIso2Code: 'ST',
    countryIso3Code: 'STP',
    currency: {
      code: 'STD',
      description: 'Dobra',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:SAU',
    name: 'Saudi Arabia',
    countryIso2Code: 'SA',
    countryIso3Code: 'SAU',
    currency: {
      code: 'SAR',
      description: 'Saudi Riyal',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:SEN',
    name: 'Senegal',
    countryIso2Code: 'SN',
    countryIso3Code: 'SEN',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:SRB',
    name: 'Serbia',
    countryIso2Code: 'RS',
    countryIso3Code: 'SRB',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:SYC',
    name: 'Seychelles',
    countryIso2Code: 'SC',
    countryIso3Code: 'SYC',
    currency: {
      code: 'SCR',
      description: 'Seychelles Rupee',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:SLE',
    name: 'Sierra Leone',
    countryIso2Code: 'SL',
    countryIso3Code: 'SLE',
    currency: {
      code: 'SLL',
      description: 'Leone',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:SGP',
    name: 'Singapore',
    countryIso2Code: 'SG',
    countryIso3Code: 'SGP',
    currency: {
      code: 'SGD',
      description: 'Singapore Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:SXM',
    name: 'Sint Maarten',
    countryIso2Code: 'SX',
    countryIso3Code: 'SXM',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:SVK',
    name: 'Slovakia',
    countryIso2Code: 'SK',
    countryIso3Code: 'SVK',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:SVN',
    name: 'Slovenia',
    countryIso2Code: 'SI',
    countryIso3Code: 'SVN',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:SLB',
    name: 'Solomon Islands',
    countryIso2Code: 'SB',
    countryIso3Code: 'SLB',
    currency: {
      code: 'SBD',
      description: 'Solomon Islands Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:SOM',
    name: 'Somalia',
    countryIso2Code: 'SO',
    countryIso3Code: 'SOM',
    currency: {
      code: 'SOS',
      description: 'Somali Shilling',
    },
    unitsOfMeasure: {
      distance: 'MILES',
      volume: 'GALLONS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:ZAF',
    name: 'South Africa',
    countryIso2Code: 'ZA',
    countryIso3Code: 'ZAF',
    currency: {
      code: 'ZAR',
      description: 'Rand',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:SGS',
    name: 'South Georgia And The South Sandwich Islands',
    countryIso2Code: 'GS',
    countryIso3Code: 'SGS',
    currency: {
      code: 'GBP',
      description: 'Pound Sterling',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:SSD',
    name: 'South Sudan',
    countryIso2Code: 'SS',
    countryIso3Code: 'SSD',
    unitsOfMeasure: {
      distance: 'MILES',
      volume: 'GALLONS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:ESP',
    name: 'Spain',
    countryIso2Code: 'ES',
    countryIso3Code: 'ESP',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:LKA',
    name: 'Sri Lanka',
    countryIso2Code: 'LK',
    countryIso3Code: 'LKA',
    currency: {
      code: 'LKR',
      description: 'Sri Lanka Rupee',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:SDN',
    name: 'Sudan',
    countryIso2Code: 'SD',
    countryIso3Code: 'SDN',
    currency: {
      code: 'SDP',
      description: 'Sudanese pound',
    },
    unitsOfMeasure: {
      distance: 'MILES',
      volume: 'GALLONS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:SUR',
    name: 'Suriname',
    countryIso2Code: 'SR',
    countryIso3Code: 'SUR',
    currency: {
      code: 'SRG',
      description: 'Suriname Guilder',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:SJM',
    name: 'Svalbard And Jan Mayen',
    countryIso2Code: 'SJ',
    countryIso3Code: 'SJM',
    currency: {
      code: 'NOK',
      description: 'Norwegian Krone',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:SWZ',
    name: 'Swaziland',
    countryIso2Code: 'SZ',
    countryIso3Code: 'SWZ',
    currency: {
      code: 'ZAR',
      description: 'Rand',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:SWE',
    name: 'Sweden',
    countryIso2Code: 'SE',
    countryIso3Code: 'SWE',
    currency: {
      code: 'SEK',
      description: 'Swedish Krona',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:CHE',
    name: 'Switzerland',
    countryIso2Code: 'CH',
    countryIso3Code: 'CHE',
    currency: {
      code: 'CHF',
      description: 'Swiss Franc',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:SYR',
    name: 'Syria',
    countryIso2Code: 'SY',
    countryIso3Code: 'SYR',
    currency: {
      code: 'SYP',
      description: 'Syrian Pound',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:TWN',
    name: 'Taiwan',
    countryIso2Code: 'TW',
    countryIso3Code: 'TWN',
    currency: {
      code: 'TWD',
      description: 'New Taiwan Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:TJK',
    name: 'Tajikistan',
    countryIso2Code: 'TJ',
    countryIso3Code: 'TJK',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:TZA',
    name: 'Tanzania, United Republic Of',
    countryIso2Code: 'TZ',
    countryIso3Code: 'TZA',
    currency: {
      code: 'TZS',
      description: 'Tanzanian Shilling',
    },
    unitsOfMeasure: {
      distance: 'MILES',
      volume: 'GALLONS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:THA',
    name: 'Thailand',
    countryIso2Code: 'TH',
    countryIso3Code: 'THA',
    currency: {
      code: 'THB',
      description: 'Baht',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:TLS',
    name: 'Timor-Leste',
    countryIso2Code: 'TL',
    countryIso3Code: 'TLS',
    currency: {
      code: 'TPE',
      description: 'Timor Escudo',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:TGO',
    name: 'Togo',
    countryIso2Code: 'TG',
    countryIso3Code: 'TGO',
    currency: {
      code: 'XOF',
      description: 'CFA Franc BCEAO ',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:TKL',
    name: 'Tokelau',
    countryIso2Code: 'TK',
    countryIso3Code: 'TKL',
    currency: {
      code: 'NZD',
      description: 'New Zealand Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:TON',
    name: 'Tonga',
    countryIso2Code: 'TO',
    countryIso3Code: 'TON',
    currency: {
      code: 'TOP',
      description: 'Pa�anga',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:TTO',
    name: 'Trinidad And Tobago',
    countryIso2Code: 'TT',
    countryIso3Code: 'TTO',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:TUN',
    name: 'Tunisia',
    countryIso2Code: 'TN',
    countryIso3Code: 'TUN',
    currency: {
      code: 'TND',
      description: 'Tunisian Dinar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:TUR',
    name: 'Turkey',
    countryIso2Code: 'TR',
    countryIso3Code: 'TUR',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:TKM',
    name: 'Turkmenistan',
    countryIso2Code: 'TM',
    countryIso3Code: 'TKM',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:TCA',
    name: 'Turks And Caicos Islands',
    countryIso2Code: 'TC',
    countryIso3Code: 'TCA',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:TUV',
    name: 'Tuvalu',
    countryIso2Code: 'TV',
    countryIso3Code: 'TUV',
    currency: {
      code: 'AUD',
      description: 'Australian Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:UGA',
    name: 'Uganda',
    countryIso2Code: 'UG',
    countryIso3Code: 'UGA',
    currency: {
      code: 'UGS',
      description: 'Uganda Shilling',
    },
    unitsOfMeasure: {
      distance: 'MILES',
      volume: 'GALLONS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:UKR',
    name: 'Ukraine',
    countryIso2Code: 'UA',
    countryIso3Code: 'UKR',
    currency: {
      code: 'EUR',
      description: 'euro',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:ARE',
    name: 'United Arab Emirates',
    countryIso2Code: 'AE',
    countryIso3Code: 'ARE',
    currency: {
      code: 'AED',
      description: 'UAE Dirham',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:GBR',
    name: 'United Kingdom',
    countryIso2Code: 'GB',
    countryIso3Code: 'GBR',
    currency: {
      code: 'GBP',
      description: 'Pound Sterling',
    },
    unitsOfMeasure: {
      distance: 'MILES',
      volume: 'GALLONS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:USA',
    name: 'United States',
    countryIso2Code: 'US',
    countryIso3Code: 'USA',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'MILES',
      volume: 'GALLONS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:UMI',
    name: 'United States Minor Outlying Islands',
    countryIso2Code: 'UM',
    countryIso3Code: 'UMI',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:URY',
    name: 'Uruguay',
    countryIso2Code: 'UY',
    countryIso3Code: 'URY',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:UZB',
    name: 'Uzbekistan',
    countryIso2Code: 'UZ',
    countryIso3Code: 'UZB',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:VUT',
    name: 'Vanuatu',
    countryIso2Code: 'VU',
    countryIso3Code: 'VUT',
    currency: {
      code: 'VUV',
      description: 'Vatu',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:VEN',
    name: 'Venezuela',
    countryIso2Code: 'VE',
    countryIso3Code: 'VEN',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:VNM',
    name: 'Viet Nam',
    countryIso2Code: 'VN',
    countryIso3Code: 'VNM',
    currency: {
      code: 'VND',
      description: 'Dong',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:VGB',
    name: 'Virgin Islands, British',
    countryIso2Code: 'VG',
    countryIso3Code: 'VGB',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'MILES',
      volume: 'GALLONS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:VIR',
    name: 'Virgin Islands, U.S.',
    countryIso2Code: 'VI',
    countryIso3Code: 'VIR',
    currency: {
      code: 'USD',
      description: 'US Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:WLF',
    name: 'Wallis And Futuna',
    countryIso2Code: 'WF',
    countryIso3Code: 'WLF',
    currency: {
      code: 'XPF',
      description: 'CFP Franc',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:ESH',
    name: 'Western Sahara',
    countryIso2Code: 'EH',
    countryIso3Code: 'ESH',
    currency: {
      code: 'MAD',
      description: 'Moroccan Dirham',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:YEM',
    name: 'Yemen',
    countryIso2Code: 'YE',
    countryIso3Code: 'YEM',
    currency: {
      code: 'YER',
      description: 'Yemeni Rial',
    },
    unitsOfMeasure: {
      distance: 'MILES',
      volume: 'GALLONS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:ZMB',
    name: 'Zambia',
    countryIso2Code: 'ZM',
    countryIso3Code: 'ZMB',
    currency: {
      code: 'ZMK',
      description: 'Kwacha',
    },
    unitsOfMeasure: {
      distance: 'MILES',
      volume: 'GALLONS',
    },
  },
  {
    urn: 'urn:com.ehi:xqa:reference:location:country:ZWE',
    name: 'Zimbabwe',
    countryIso2Code: 'ZW',
    countryIso3Code: 'ZWE',
    currency: {
      code: 'ZWD',
      description: 'Zimbabwe Dollar',
    },
    unitsOfMeasure: {
      distance: 'KILOMETERS',
      volume: 'LITERS',
    },
  },
];
