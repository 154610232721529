import { FC, useMemo, useState } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { StyledDivider } from './QuickRes.styles';
import { VehicleAutoCompleteField } from 'components/shared/forms/VehicleAutoCompleteField';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { QuickResFields, QuickResVehicleProps } from 'components/quickRes/QuickResTypes';
import { useFormContext, useWatch } from 'react-hook-form';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { deleteVehicleClassSelection, updateVehicleClassSelection } from 'services/booking/bookingService';
import { EHI_DOMAINS, generateReferenceUrn } from 'utils/urnUtils';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { useVehicleRates } from 'services/booking/useVehicleRates';
import { transformVehicleListFromApiData } from 'components/shared/uiModels/vehicle/vehicleTransformer';
import { EMPTY_VALUE } from 'components/quickRes/quickResUtils';

const QuickResVehicle: FC<QuickResVehicleProps> = ({ vehicleContent = [] }) => {
  const { t } = useTranslations();
  const appConfig = getAppConfigCache();
  const defaultEhiDatabase = appConfig?.defaultEhiDatabase ?? '';
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const [isVehicleUpdating, setIsVehicleUpdating] = useState<boolean>(false);
  const { setValue, setError, clearErrors } = useFormContext();
  const { vehicleRates, isLoading: isVehicleRatesLoading } = useVehicleRates();

  const [carClass] = useWatch({
    name: [QuickResFields.VehicleClass],
  });

  const vehicles = useMemo(
    () => transformVehicleListFromApiData(vehicleContent, vehicleRates),
    [vehicleRates, vehicleContent]
  );

  const updateVehicleClass = async (vehicleToAdd: string | undefined) => {
    setIsVehicleUpdating(true);

    try {
      const callToUpdateVehicle = !vehicleToAdd
        ? await deleteVehicleClassSelection(bookingEditorId)
        : await updateVehicleClassSelection(bookingEditorId, {
            type: 'PAYLATER',
            vehicleClass: generateReferenceUrn(
              EHI_DOMAINS.vehicle.name,
              EHI_DOMAINS.vehicle.vehicleClass,
              vehicleToAdd,
              defaultEhiDatabase
            ),
          });
      const { errors } = await updateAndRefresh(async () => callToUpdateVehicle);
      if (errors) {
        setError(QuickResFields.VehicleClass, { message: errors?.[0]?.localizedMessage });
      } else {
        clearErrors(QuickResFields.VehicleClass);
        setValue(QuickResFields.VehicleClass, vehicleToAdd ? vehicleToAdd : EMPTY_VALUE);
        document.getElementsByName(QuickResFields.VehicleClass).item(0).blur();
      }
    } catch (error) {
      setError(QuickResFields.VehicleClass, { message: (error as EhiErrors)?.errors?.[0]?.localizedMessage });
    } finally {
      setIsVehicleUpdating(false);
    }
  };

  return (
    <GridContainer>
      <GridItem sm={12}>
        <h3 data-testid={'res-vehicle'}>{t('common.vehicle')}</h3>
      </GridItem>
      <GridItem sm={12}>
        <VehicleAutoCompleteField
          vehicles={vehicles}
          isLoading={isVehicleUpdating || isVehicleRatesLoading}
          selectedVehicleClass={carClass}
          setVehicle={(vehicle) => updateVehicleClass(vehicle)}
          resetVehicle={() => updateVehicleClass(undefined)}
        />
      </GridItem>
      <GridItem xs={12} sm={12}>
        <StyledDivider />
      </GridItem>
    </GridContainer>
  );
};
export default QuickResVehicle;
