import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 *
 * @param paramKeys
 * @returns Record object with param keys as keys
 */
export const useQueryParamsByKey = (paramKeys: string[]): Record<string, string> => {
  const [searchParams] = useSearchParams();

  return useMemo(() => {
    return paramKeys.reduce((all, it) => {
      all[it] = searchParams.get(it) || '';
      return all;
    }, {} as Record<string, string>);
  }, [searchParams, paramKeys]);
};
