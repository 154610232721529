import { useAppDispatch } from 'redux/hooks';
import { Reservation, ReservationEditorCreator } from 'services/booking/bookingTypes';
import { createNewReservationEditor, retrieveReservation } from 'services/booking/bookingService';
import { setBookingEditorId, setReservation } from 'redux/slices/booking/bookingEditorSlice';
import { safelyCatchError, UNKNOWN_ERROR } from 'utils/errorUtils';
import {
  generateWorkflowId,
  getLocationHeaderFromUrl,
  persistEditorSessionData,
  persistEditorSessionDataWithResNumber,
  persistReservationSessionData,
} from 'components/shared/preprocessor/ReservationSessionHelper';
import { useAuthStore } from '@ehi/auth';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { TransactionTypes } from 'utils/routing/TransactionTypes';
import { loadCounterCookie, loadEhiLocationCookie } from '@ehi/location';
import { ServiceResultType } from 'services/types/ServiceResultTypes';
import { RouterPaths } from 'app/router/RouterPaths';
import { useNavigate } from 'react-router-dom';
import { generateSearchParams } from 'utils/routing/urlUtils';
import { InternalTransactionParams } from 'utils/routing/InternalTransactionParams';
import { generateUrnForReservation } from 'utils/urnUtils';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { useRefreshEditor } from 'hooks/bookingEditor/useRefreshEditor';

export const useStartReservationSession = () => {
  const dispatch = useAppDispatch();
  const { eid } = useAuthStore();
  const cookieLocation = loadEhiLocationCookie();
  const counterCookie = loadCounterCookie();
  const navigate = useNavigate();
  const appConfig = getAppConfigCache();
  const { refreshEditor } = useRefreshEditor();

  const unknownError: EhiErrors = {
    errors: [
      {
        ...UNKNOWN_ERROR,
        supportInfo: 'FAILED TO RETRIEVE RESERVATION',
      },
    ],
  };

  const unknownStartEditorSessionError: EhiErrors = {
    errors: [
      {
        ...UNKNOWN_ERROR,
        supportInfo: 'FAILED TO START BOOKING EDITOR',
      },
    ],
  };

  /**
   * Retrieves reservation by resNumber and stores data in the redux state
   * Also, calls renter for driver info and stores data in the redux state
   * Also, it also generates a workflow id with the format of eid:timestamp:resNumber and
   * stores it in local storage.
   * Also, persists reservation info in local storage for rehydration on browser refresh
   * @return {Promise<ServiceResultType<{ editorId: string }>>}
   * @param resNumber
   */
  const startViewOnlySession = async (resNumber: string): Promise<ServiceResultType> => {
    generateWorkflowId(eid, resNumber);
    let reservationData: Reservation | undefined;
    try {
      reservationData = await retrieveReservation(resNumber);
      if (reservationData === undefined) {
        navigate(
          `${RouterPaths.Search}?${generateSearchParams({
            [InternalTransactionParams.Res]: resNumber,
            [InternalTransactionParams.NotFound]: String(true),
          })}`
        );
      }
    } catch (error) {
      return { ...safelyCatchError(error) };
    }

    if (reservationData) {
      dispatch(setReservation({ reservation: reservationData }));
      persistReservationSessionData(resNumber, {
        resNumber: resNumber,
        transactionType: TransactionTypes.View,
        counter: counterCookie?.counterId ?? '',
        location: cookieLocation,
        returnUrl: '',
      });
      return { success: true };
    }
    return unknownError;
  };

  /**
   * Creates a reservation editor sessionId stores that in the redux state
   * Also, it also generates a workflow id with the format of eid:timestamp and
   * stores it in local storage.
   * @return {Promise<ServiceResultType<{ editorId: string }>>}
   */
  const startOpenEditorSession = async (transactionType: TransactionTypes): Promise<ServiceResultType> => {
    generateWorkflowId(eid);

    try {
      const { headers } = await createNewReservationEditor();
      const createdEditorId = getLocationHeaderFromUrl(headers?.location);

      if (createdEditorId) {
        persistEditorSessionData({
          editorId: createdEditorId,
          transactionType: transactionType,
          counter: counterCookie?.counterId ?? '',
          location: cookieLocation,
          // Note: abstract the returnUrl from iframe props from P&M deeplink navigation
          returnUrl: '',
        });
        dispatch(setBookingEditorId(createdEditorId));
        return { data: { editorId: createdEditorId } };
      }
      return unknownStartEditorSessionError;
    } catch (error) {
      return { ...safelyCatchError(error) };
    }
  };

  const startModifyEditorSession = async (resNumber: string): Promise<ServiceResultType> => {
    generateWorkflowId(eid, resNumber);
    try {
      const request = {
        reservation: generateUrnForReservation(resNumber, appConfig?.defaultEhiDatabase ?? ''),
      } as ReservationEditorCreator;

      const { headers } = await createNewReservationEditor(request);
      const createdEditorId = getLocationHeaderFromUrl(headers?.location);

      if (createdEditorId) {
        dispatch(setBookingEditorId(createdEditorId));
        const { errors: editorErrors } = await refreshEditor(createdEditorId);
        if (editorErrors) {
          return { errors: editorErrors };
        }
        persistEditorSessionDataWithResNumber(resNumber, {
          resNumber: resNumber,
          editorId: createdEditorId,
          transactionType: TransactionTypes.Modify,
          counter: counterCookie?.counterId ?? '',
          location: cookieLocation,
          // Note: abstract the returnUrl from iframe props from P&M deeplink navigation
          returnUrl: '',
        });
        return { data: { editorId: createdEditorId } };
      }

      return unknownStartEditorSessionError;
    } catch (error) {
      return { ...safelyCatchError(error) };
    }
  };

  return {
    startViewOnlySession,
    startOpenEditorSession,
    startModifyEditorSession,
  };
};
