import { getDefaultAxiosInstance } from 'components/shared/axios/axiosConfiguration';
import { EquipmentType, ProtectionType, AncillaryType } from './rentalReferenceTypes';

const RENTAL_REFERENCE_ENDPOINT = '/reference/rental';

export const retrieveAllEquipmentTypes = async (): Promise<EquipmentType[]> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.get(`${RENTAL_REFERENCE_ENDPOINT}/equipmentType`, {
    genericUrl: `${RENTAL_REFERENCE_ENDPOINT}/equipmentType`,
  });
  return response.data;
};

export const retrieveAllProtectionTypes = async (countryCode: string): Promise<ProtectionType[]> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.get(`${RENTAL_REFERENCE_ENDPOINT}/protectionType?saleCountry=${countryCode}`, {
    genericUrl: `${RENTAL_REFERENCE_ENDPOINT}/protectionType?saleCountry=${countryCode}`,
  });
  return response.data;
};

export const retrieveAllAncillaryFeeTypes = async (): Promise<AncillaryType[]> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.get(`${RENTAL_REFERENCE_ENDPOINT}/ancillaryFeeType`, {
    genericUrl: `${RENTAL_REFERENCE_ENDPOINT}/ancillaryFeeType`,
  });
  return response.data;
};
