import { FC } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { HeaderName, HeaderValue } from 'components/customerHeader/CustomerHeader.styles';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';

const QuickResRateSource: FC = () => {
  const { t } = useTranslations();

  return (
    <GridContainer>
      <GridItem xs={12} sm={4} md={6}>
        <h3>{t('rateAndBilling.rateSource')}</h3>
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <HeaderValue sx={{ color: 'text.secondary' }}>{t('rateAndBilling.rateProduct')}</HeaderValue>
          </GridItem>
          <GridItem xs={12} sm={12}>
            <HeaderName sx={{ color: 'black' }}>{'EW1M'}</HeaderName>
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};
export default QuickResRateSource;
