import { RouterPaths } from 'app/router/RouterPaths';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useTranslations } from 'components/shared/i18n';
import { useRefreshEditor } from 'hooks/bookingEditor/useRefreshEditor';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { selectAddons, selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';
import { ServiceResultType } from 'services/types/ServiceResultTypes';
import { updateProtections } from 'services/booking/bookingService';
import { formatProtection } from 'utils/peoUtils';

import {
  BaseProtection,
  ProtectionFormModel,
  ProtectionsFormFields,
  ProtectionsItemFields,
} from 'components/flexFlow/peo/peoTypes';

export type SaveProtections = {
  saveProtectionAndRefreshEditor: (protectionModal: ProtectionFormModel) => Promise<ServiceResultType>;
  selectedProtections: BaseProtection[];
  availableProtections: BaseProtection[];
};

export const useSaveProtections = (): SaveProtections => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const addOns = useAppSelector(selectAddons);
  const { showAlert } = useAlert();
  const { refreshEditor } = useRefreshEditor();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();

  const availableProtections = Object.entries(addOns?.protections?.available || []).flatMap(
    ([category, protectionsForCategory]) => {
      return protectionsForCategory.map((availableProtections) => {
        if (!availableProtections.selectability || !availableProtections.rate) {
          throw new Error('Missing required protection info');
        }
        return formatProtection(availableProtections, category);
      });
    }
  );
  const selectedProtections = (addOns?.protections?.selected || []).map((item) => {
    return formatProtection(item);
  });

  const handleErrors = useCallback(
    (errors: ResponseMessage[]) => {
      showAlert({
        title: t('error.error'),
        description: t('error.editorError', {
          error: errors?.[0].localizedMessage || '',
        }),
        primaryActionText: t('error.reload'),
        secondaryActionText: t('error.home'),
        open: true,
        onPrimaryAction: () => {
          return refreshEditor(bookingEditorId);
        },
        onSecondaryAction: () => {
          navigate(RouterPaths.Search);
        },
        onClose: () => {
          return;
        },
      });
    },
    [bookingEditorId, navigate, refreshEditor, showAlert, t]
  );

  const saveProtectionAndRefreshEditor = async (protectionModal: ProtectionFormModel) => {
    const protectionUpdates = protectionModal[ProtectionsFormFields.Items]
      ?.filter((protectionItemModel) => protectionItemModel[ProtectionsItemFields.Checked])
      ?.map((protectionItemModel) => {
        const item = protectionItemModel[ProtectionsItemFields.Item];
        return { type: item.type };
      });

    const { errors } = await updateAndRefresh(async () => {
      await updateProtections(bookingEditorId, protectionUpdates);
    });
    if (errors) {
      handleErrors(errors);
    }
    return { success: !!errors, errors: errors };
  };

  return {
    saveProtectionAndRefreshEditor,
    availableProtections,
    selectedProtections,
  };
};
