import { createContext, FunctionComponent, useCallback, useContext, useEffect, useRef } from 'react';
import { NavigateFunction, To, useLocation } from 'react-router-dom';
import { SaveAndNavigateContextValue, SaveOptions } from 'context/saveOnNavigate/SaveOnNavigateContextTypes';
import { logDebug } from 'utils/logUtils';

const SaveOnNavigateContext = createContext<SaveAndNavigateContextValue | undefined>(undefined);

export const useSaveOnNavigateContext = () => {
  const context = useContext(SaveOnNavigateContext);

  if (!context) {
    throw Error('SaveOnNavigateContext is not initialized');
  }

  return context;
};

export const SaveOnNavigateProvider: FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  // use ref so that registration doesn't cause infinite re-renders
  const saveOptionsRef = useRef<SaveOptions | undefined>(undefined);
  const savePath = useRef<string | undefined>(undefined);
  const { pathname } = useLocation();

  useEffect(() => {
    // clear out save options when path changes
    if (savePath.current && pathname !== savePath.current) {
      savePath.current = pathname;
      saveOptionsRef.current = undefined;
    }
  }, [pathname]);

  const registerSaveAction = useCallback(
    (saveOptions: SaveOptions) => {
      savePath.current = pathname;
      saveOptionsRef.current = saveOptions;
    },
    [pathname]
  );

  const saveOnNavigate = useCallback(
    async (
      navigate: NavigateFunction,
      to: To | -1,
      options?: {
        replace?: boolean;
        state?: any;
      }
    ) => {
      if (saveOptionsRef?.current?.when) {
        try {
          const result = await saveOptionsRef.current.onSave();

          if (!result?.errors?.length) {
            saveOptionsRef.current = undefined;
            navigate(to as any, options);
          }
        } catch (er) {
          // Generally ignore this, but a log might be handy for debugging
          logDebug('Save and navigate failed', er);
        }
      } else {
        saveOptionsRef.current = undefined;
        navigate(to as any, options);
      }
    },
    []
  );

  return (
    <SaveOnNavigateContext.Provider value={{ registerSaveAction, saveOnNavigate }}>
      {children}
    </SaveOnNavigateContext.Provider>
  );
};
