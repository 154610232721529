import { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { BranchLookupFields, SearchByType } from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupTypes';
import {
  buttonImages,
  EmptyPlaceHolder,
  StyledFilterBox,
  StyledFilterButtonContainer,
  StyledFilterContainer,
} from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookup.styles';
import { BranchLookupIconButton } from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupIconButton';
import BrandFilterDropDown from 'components/flexFlow/whenAndWhere/branchLookup/brandFilter/BrandFilterDropDown';
import { DistanceDropDown } from 'components/flexFlow/whenAndWhere/branchLookup/DistanceDropDown';
import { LocationTypesDropDown } from 'components/flexFlow/whenAndWhere/branchLookup/LocationTypesDropDown';
import { OperationalLocationType } from 'services/location/locationReferenceTypes';

type BranchLookupFilterProps = {
  onMapViewToggle: (isMap: boolean) => void;
  hasResults: boolean;
  locationTypeOptions: OperationalLocationType[];
};

export const BranchLookupFilters: FC<BranchLookupFilterProps> = ({
  onMapViewToggle,
  hasResults,
  locationTypeOptions,
}) => {
  const { setValue } = useFormContext();

  const listButtonOnClick = () => {
    setValue(BranchLookupFields.IsListButtonSelected, true);
    setValue(BranchLookupFields.IsMapButtonSelected, false);
    onMapViewToggle(false);
  };

  const mapButtonOnClick = () => {
    setValue(BranchLookupFields.IsListButtonSelected, false);
    setValue(BranchLookupFields.IsMapButtonSelected, true);
    onMapViewToggle(true);
  };

  const [isListButtonSelected, isMapButtonSelected, searchType] = useWatch({
    name: [
      BranchLookupFields.IsListButtonSelected,
      BranchLookupFields.IsMapButtonSelected,
      BranchLookupFields.SearchBy,
    ],
  });

  return (
    <StyledFilterBox gap={1}>
      <StyledFilterContainer>
        <StyledFilterButtonContainer>
          <BranchLookupIconButton
            name={'branchLookupListButton'}
            disabled={!hasResults}
            icon={buttonImages.listIcon}
            selected={isListButtonSelected}
            onClick={listButtonOnClick}
          />
          <BranchLookupIconButton
            name={'branchLookupMapButton'}
            disabled={!hasResults}
            icon={buttonImages.mapIcon}
            selected={isMapButtonSelected}
            onClick={mapButtonOnClick}
          />
        </StyledFilterButtonContainer>
        <BrandFilterDropDown />
        <LocationTypesDropDown locationTypeOptions={locationTypeOptions} />
        {searchType === SearchByType.PostalCode ? <DistanceDropDown /> : <EmptyPlaceHolder />}
      </StyledFilterContainer>
    </StyledFilterBox>
  );
};
