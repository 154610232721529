import { logException } from 'components/shared/logger/splunkLogger';
import { ErrorResponse, isRouteErrorResponse } from 'react-router-dom';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { ServiceResultType } from 'services/types/ServiceResultTypes';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';
import { AxiosError } from 'axios';

export enum AppErrorCode {
  unknownError = 'GLRES0000',
  networkError = 'GLRES1000',
  bookingEditorNotFound = 'GLRES2000',
}

export enum SpecialErrorCodes {
  NetworkTimeout = 'ETIMEDOUT',
  AxiosNetworkError = 'ERR_NETWORK',
}

export const UNKNOWN_ERROR: ResponseMessage = {
  code: AppErrorCode.unknownError,
  severity: 'ERROR',
  localizedMessage: 'Unknown Error',
};

export const isThrownError = (error: unknown): error is Error => {
  return (error as Error).message !== undefined;
};

export const isEhiError = (it: unknown): it is EhiErrors => {
  return (it as EhiErrors)?.errors !== undefined;
};

export const isAxiosError = (it: any): it is AxiosError => {
  return (it as AxiosError).isAxiosError;
};

const isResponseMessageArray = (thing: any): thing is ResponseMessage[] => {
  return Array.isArray(thing);
};

/**
 *
 * This function will execute a function and normalize errors into an errors field, and responses into the data field.
 * If the provided fn throws and ehi error it will be caught and returned as a normal response.
 * @param fn the function that will be executed and normalized.
 */
export async function executeApiCall<T>(fn: () => Promise<T>): Promise<ServiceResultType<T>> {
  let errors: EhiErrors;
  try {
    const response = await fn();
    return isEhiError(response) ? response : { data: response, success: true };
  } catch (e) {
    errors = safelyCatchError(e);
  }
  return errors;
}

// Will rethrow if not EhiErrors
export const safelyCatchError = (e: any): EhiErrors => {
  if (isEhiError(e)) {
    return e;
  } else {
    throw e;
  }
};

export const getHandledErrorMessage = (e: unknown): string | undefined => {
  let errorMessage;
  if (typeof e === 'string') {
    errorMessage = e.toUpperCase();
  } else if (e instanceof Error) {
    errorMessage = e.message;
  }
  return errorMessage;
};

export type ErrorType = Error & {
  logged: boolean;
};

export const handleError = (error: any) => {
  if (error) {
    if (isThrownError(error)) {
      const errorType = error as ErrorType;
      if (!errorType?.logged) {
        errorType.logged = true;
        // We have to manually add the message and stack field because the analytics library spreads the exception object
        // we pass in. message and stack attributes are non-enumerable fields, and so they are lost when using the spread
        // operator in some browsers (see
        // https://stackoverflow.com/questions/59874163/object-spread-an-error-results-in-no-message-property)
        logException({
          exception: { ...errorType, message: errorType.message, stack: errorType.stack },
        });
      }
    } else if (isRouteErrorResponse(error)) {
      const errorType = error as ErrorResponse & { logged: boolean };
      if (!errorType?.logged) {
        errorType.logged = true;
        logException({
          exception: {
            ...errorType,
            message: errorType.data.message ?? 'Error occurred',
            error: errorType,
            statusText: errorType.statusText,
            status: errorType.status,
          },
        });
      }
    } else {
      logException({
        exception: { message: 'Unknown error occurred', error: error },
      });
    }
  }
};

const normalizeResponseMessages = (responseStatus: string, errors: any[]): ResponseMessage[] => {
  return errors.map((it) => {
    if (!it) {
      return {
        code: responseStatus || AppErrorCode.unknownError,
        severity: 'ERROR',
      };
    }
    return {
      ...it,
      localizedMessage:
        (it.hasOwnProperty('localizedMessage') && it.localizedMessage) ||
        (it.hasOwnProperty('error') && it.error) ||
        undefined,
      code:
        (it.hasOwnProperty('code') && it.code) ||
        (it.hasOwnProperty('status') && it.status.toString()) ||
        AppErrorCode.unknownError,
      severity: it.hasOwnProperty('severity') ? it.severity : 'ERROR',
    };
  });
};

export const normalizeErrorResponseData = (errorResponseBody: any, httpStatus: number): EhiErrors => {
  let errorData = errorResponseBody;
  if (errorData.errors) {
    errorData = errorResponseBody.errors;
  }

  // API return an array, but errors like service unavailable are a single error object
  if (!Array.isArray(errorData)) {
    errorData = [errorData];
  }

  if (!errorData[0].code || !errorData[0].localizedMessage) {
    errorData = normalizeResponseMessages(httpStatus.toString(), errorData);
  }

  return { status: httpStatus, errors: errorData };
};

const normalizeAxiosError = (error: AxiosError<any>): EhiErrors => {
  if (error.response?.data) {
    return normalizeErrorResponseData(error.response.data, error.response.status);
  } else {
    return {
      status: error.response?.status,
      errors: [
        {
          code: error.response?.status?.toString() || AppErrorCode.unknownError,
          severity: 'ERROR',
        },
      ],
    };
  }
};

export const normalizeAxiosErrorCode = (errorCode: string) => {
  if (errorCode === SpecialErrorCodes.AxiosNetworkError) {
    return AppErrorCode.networkError;
  } else {
    return errorCode;
  }
};

export const normalizeError = (error: AxiosError | EhiErrors | ResponseMessage[] | any): EhiErrors => {
  let normalized: EhiErrors;
  if (isAxiosError(error)) {
    error = error as AxiosError;
    if (error.response?.data && error.request?.responseType === 'arraybuffer') {
      // here we can actually mutate the response data since the arraybuffer is not useful
      error.response.data = JSON.parse(
        String.fromCharCode.apply(null, new Uint8Array(error.response?.data) as unknown as number[])
      );
    }
    if (error.response) {
      normalized = normalizeAxiosError(error);
    } else {
      let errorCode;
      if (error?.code) {
        errorCode = normalizeAxiosErrorCode(error?.code);
      } else {
        errorCode = window.navigator.onLine ? AppErrorCode.unknownError : AppErrorCode.networkError;
      }

      normalized = {
        errors: [
          {
            code: errorCode,
            severity: 'ERROR',
            localizedMessage: error.message,
          },
        ],
      };
    }
  } else if (isEhiError(error)) {
    normalized = error;
  } else if (isThrownError(error)) {
    normalized = {
      errors: [
        {
          code: AppErrorCode.unknownError,
          severity: 'ERROR',
          localizedMessage: error.message,
        },
      ],
    };
  } else if (isResponseMessageArray(error)) {
    normalized = { errors: error };
  } else {
    normalized = {
      errors: [
        {
          code: AppErrorCode.unknownError,
          severity: 'ERROR',
        },
      ],
    };
  }

  return normalized;
};
