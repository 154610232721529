import { Fab, styled, Tab } from '@mui/material';
import { ehiTheme } from '@ehi/ui';

export const FlexiAppBar = styled('div')`
  position: fixed;
  height: 72px;
  z-index: 2;
  background-color: #f5f5f5;
  width: calc(100vw - 108px);
  padding-bottom: ${ehiTheme.spacing(1.25)};
  &.drawer-open {
    width: calc(100vw - 468px);
  }
  @media (max-width: 800px) {
    width: calc(100vw - 40px);
  }
`;

export const FlexiTab = styled(Tab)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  minWidth: '2rem',
  alignSelf: 'baseline',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'fit-content',
}));

export const FlexMenuContainer = styled('div')`
  flex: 1;
  padding: ${ehiTheme.spacing(0, 2.5, 2.5)};
`;

export const FlexFlowFab = styled(Fab)`
  position: absolute;
  bottom: ${ehiTheme.spacing(3)};
  right: ${ehiTheme.spacing(3)};
  height: 48px;
  width: 120px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), linear-gradient(0deg, #4d789d, #4d789d);
  box-shadow: 0 3px 5px 0 #00000033, 0 1px 18px 0 #0000001f, 0 6px 10px 0 #00000024;

  @media (max-width: 800px) {
    // This accounts for the global nav bar on tablet
    margin-bottom: ${ehiTheme.spacing(9)};
  }
`;
