import { FC, useEffect } from 'react';
import { BranchLookupCard } from 'components/shared/uiModels/branchLookup/branchLookupDataTypes';
import { AdvancedMarker, Map, useMap } from '@vis.gl/react-google-maps';
import BranchLookupCardDarkIcon from 'images/branchLookupCardDarkIcon.svg';
import { CardImageContainer, CardIndexBox, CardIndexTypography } from './BranchCard.styles';
import { BRANCH_LIST_OFFSET } from 'components/flexFlow/whenAndWhere/branchLookup/BranchCardList';

type BranchLookupMapProps = {
  branches: BranchLookupCard[];
};

const markerIcon = (index: number) => (
  <CardImageContainer>
    <img src={BranchLookupCardDarkIcon} alt='Branch Icon' style={{ width: '75%', height: '75%' }} />
    <CardIndexBox>
      <CardIndexTypography variant='caption' selected={false}>
        {index + 1}
      </CardIndexTypography>
    </CardIndexBox>
  </CardImageContainer>
);

export const BranchLookupMap: FC<BranchLookupMapProps> = ({ branches }) => {
  const map = useMap();

  useEffect(() => {
    if (map && branches[0].geoLocation) {
      // We need this to update the center once the map is Initialized based on search
      map?.setCenter(branches[0].geoLocation);
    }
  }, [map, branches]);
  return (
    <Map
      // Todo:  Google map id is unique for each instance, create a id on google cloud.
      style={{
        height: window.innerHeight - window.innerHeight * 0.06 - BRANCH_LIST_OFFSET,
        width: '96vw',
        position: 'relative',
      }}
      defaultZoom={13}
      mapId={'DEMO_ID'}
      streetViewControl={false}
      zoomControl={false}
      fullscreenControl={false}
      mapTypeControl={false}
      defaultCenter={branches[0].geoLocation}>
      {branches.map((branch, index) => {
        return (
          <AdvancedMarker key={index} position={branch.geoLocation}>
            {markerIcon(index)}
          </AdvancedMarker>
        );
      })}
    </Map>
  );
};
