import { FC, useEffect } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { useFormContext, useWatch } from 'react-hook-form';
import { CreateDriverFields } from 'components/flexFlow/driver/createDriver/CreateDriverTypes';
import { CountrySelectField } from 'components/shared/forms/CountrySelectField';
import { piiField } from '@ehi/support';
import { DatePickerField } from 'components/shared/forms/DatePickerField';
import {
  DVLA_LICENSE_VALUE,
  EMPTY_VALUE,
  FRANCE_LICENSE_FIELDS,
  GERMANY_LICENSE_FIELDS,
  getAgeOption,
  resetFieldsClearErrors,
  SPAIN_LICENSE_FIELDS,
} from 'components/flexFlow/driver/createDriver/createDriverUtils';
import { FormSectionHeader, Section } from 'components/flexFlow/driver/createDriver/CreateDriverStyles';
import { CorporateCountry } from 'utils/countryUtils';
import {
  IdentificationNumberField,
  LicenseDVLAField,
  LicenseIssuerField,
  PlaceOfBirthField,
  SpainSubSection,
} from 'components/flexFlow/driver/createDriver/section/LicenseSubSections';
import { DateTime } from 'luxon';
import { calculateAge, toDateTimeString, YEAR_MONTH_DAY_FORMAT } from 'utils/dateUtils';
import { FormTextField } from 'components/shared/forms/FormTextField';

export const LicenseSection: FC = () => {
  const { t } = useTranslations();
  const { clearErrors, resetField, setValue } = useFormContext();
  const [licenseCountry] = useWatch({
    name: [CreateDriverFields.LicenseCountry],
  });

  useEffect(() => {
    if (licenseCountry === CorporateCountry.Spain) {
      setValue(CreateDriverFields.LicenseIssuerRegion, CorporateCountry.Spain);
    } else if (licenseCountry === CorporateCountry.GreatBritain) {
      setValue(CreateDriverFields.LicenseIssuerRegion, DVLA_LICENSE_VALUE);
    } else {
      setValue(CreateDriverFields.LicenseIssuerRegion, EMPTY_VALUE);
    }
  }, [licenseCountry, setValue]);

  const setAgeDropdown = async (date: DateTime | string) => {
    if (!date) {
      setValue(CreateDriverFields.Age, EMPTY_VALUE);
    } else {
      const dateString = toDateTimeString(date, YEAR_MONTH_DAY_FORMAT);
      const age = dateString && calculateAge(dateString);
      const ageOption = age && getAgeOption(age);
      setValue(CreateDriverFields.Age, ageOption);
    }
  };

  return (
    <Section className={piiField} data-testid={'licenseSection'}>
      <FormSectionHeader>{t('driver.license')}</FormSectionHeader>
      <GridContainer>
        <GridItem sm={6}>
          <CountrySelectField
            name={CreateDriverFields.LicenseCountry}
            label={t('driver.issuingCountry')}
            data-testid={'licenseCountry'}
            onChange={(event) => {
              resetFieldsClearErrors([CreateDriverFields.LicenseIssuerRegion], resetField, clearErrors);
              licenseCountry === CorporateCountry.Germany &&
                resetFieldsClearErrors(GERMANY_LICENSE_FIELDS, resetField, clearErrors);
              licenseCountry === CorporateCountry.France &&
                resetFieldsClearErrors(FRANCE_LICENSE_FIELDS, resetField, clearErrors);
              licenseCountry === CorporateCountry.Spain &&
                resetFieldsClearErrors(SPAIN_LICENSE_FIELDS, resetField, clearErrors);
              setValue(CreateDriverFields.LicenseCountry, event.target.value);
            }}
          />
        </GridItem>
      </GridContainer>
      {licenseCountry && (
        <>
          <GridContainer>
            <GridItem sm={6} data-testid={'licenseNumberGridItem'}>
              <FormTextField
                name={CreateDriverFields.LicenseNumber}
                label={t('driver.licenseHash')}
                data-testid={'licenseNumber'}
                type='text'
                inputProps={{ maxLength: 50 }}
                required
              />
            </GridItem>
            <LicenseIssuerField licenseCountry={licenseCountry} />
          </GridContainer>
          <GridContainer>
            {licenseCountry === CorporateCountry.Germany && <IdentificationNumberField />}
            <GridItem sm={4} data-testid={'licenseExpirationGridItem'}>
              <DatePickerField
                name={CreateDriverFields.LicenseExpirationDate}
                label={t('driver.expDate')}
                required={
                  licenseCountry === CorporateCountry.UnitedStates || licenseCountry === CorporateCountry.Canada
                }
              />
            </GridItem>
            {licenseCountry === CorporateCountry.GreatBritain && <LicenseDVLAField />}
            <GridItem sm={4} data-testid={'licenseIssuedDateGridItem'}>
              <DatePickerField
                name={CreateDriverFields.LicenseIssuedDate}
                label={t('driver.issueDate')}
                disableFuture
              />
            </GridItem>
            <GridItem sm={4} data-testid={'dobGridItem'}>
              <DatePickerField
                name={CreateDriverFields.DOB}
                label={t('driver.dateOfBirth')}
                disableFuture
                required
                submitOnChange={setAgeDropdown}
              />
            </GridItem>
            {licenseCountry === CorporateCountry.France && <PlaceOfBirthField />}
          </GridContainer>
          {licenseCountry === CorporateCountry.Spain && <SpainSubSection />}
        </>
      )}
    </Section>
  );
};
