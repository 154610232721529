import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexFlowTypes';
import { FlexFlowCard, FlexiFlowCardInnerContainer } from 'components/shared/ui/card/Card';
import { StickyCardNavigation } from 'components/shared/ui/card/StickyCardNavigation';
import CardContent from '@mui/material/CardContent';
import { DividerWithFullWidth } from 'components/shared/ui/styles/Divider.styles';
import { RateSourceSection } from 'components/flexFlow/rateAndBilling/section/RateSourceSection';
import { BillToSection } from 'components/flexFlow/rateAndBilling/section/BillToSection';
import { VoucherSection } from 'components/flexFlow/rateAndBilling/section/VoucherSection';
import { VehicleShopSection } from 'components/flexFlow/rateAndBilling/section/VehicleShopSection';
import { CouponSection } from 'components/flexFlow/rateAndBilling/section/CouponSection';
import { AdditionalInfoSection } from 'components/flexFlow/rateAndBilling/section/AdditionalInfoSection';
import { mapLoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewUtils';

export const RateAndBilling: FlexiFunctionComponent = ({ onNext, onPrevious }) => {
  return (
    <FlexFlowCard loadingState={mapLoadingState(false, false)}>
      <StickyCardNavigation onPrevious={onPrevious} onNext={onNext} showExitToRentalSummary={true} />
      <CardContent>
        <FlexiFlowCardInnerContainer>
          <RateSourceSection />
          <DividerWithFullWidth />
          <BillToSection />
          <DividerWithFullWidth />
          <VehicleShopSection />
          <DividerWithFullWidth />
          <VoucherSection />
          <DividerWithFullWidth />
          <CouponSection />
          <DividerWithFullWidth />
          <AdditionalInfoSection />
        </FlexiFlowCardInnerContainer>
      </CardContent>
    </FlexFlowCard>
  );
};
