export const formatPhoneNumber = (value: string | undefined): string => {
  if (!value || !value?.trim()?.length) {
    return '';
  }

  if (value.length > 10) {
    return value;
  }

  const phoneRegex = /^\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/;
  return value.replace(phoneRegex, '($1) $2-$3');
};
