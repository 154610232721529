import { memo, ReactNode } from 'react';
import { FieldValues, FormProvider, FormProviderProps } from 'react-hook-form';
import { ServiceResultType } from 'services/types/ServiceResultTypes';
import { useFormSaveOnNavigate } from 'hooks/useFormSaveOnNavigate';

export type SavingFormProviderProps<TValues extends FieldValues = FieldValues> = FormProviderProps<TValues> & {
  submitFn: (values: TValues) => Promise<ServiceResultType>;
  alwaysBlock?: boolean;
};

/**
 *
 * This is a convenience component to wrap react-hook-form's `FormProvider`,
 * and the functionality to submit the form upon `saveOnNavigate`
 *
 */
export const SavingFormProvider = <TValues extends FieldValues = FieldValues>({
  submitFn,
  alwaysBlock,
  children,
  ...props
}: SavingFormProviderProps<TValues>) => {
  return (
    <FormProvider {...props}>
      <SavingFormProviderContentWrapper submitFn={submitFn} alwaysBlock={alwaysBlock}>
        {children}
      </SavingFormProviderContentWrapper>
    </FormProvider>
  );
};

export type SavingFormProviderContentProps = {
  submitFn: (values: any) => Promise<ServiceResultType>;
  alwaysBlock?: boolean;
  children: ReactNode;
};

export const SavingFormProviderContentWrapper = memo(
  ({ submitFn, alwaysBlock, children }: SavingFormProviderContentProps) => {
    useFormSaveOnNavigate(submitFn, alwaysBlock);
    return <>{children}</>;
  }
);
