import { Box, Grid } from '@mui/material';
import { Body1, Body2, ehiTheme } from '@ehi/ui';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { StyledWarningIcon } from 'components/shared/ui/styles/WarningIcon.styles';
import { formatName } from 'services/renter/driverTransformer';
import { formatPhoneNumber } from 'utils/phoneNumberUtils';
import { mask } from 'utils/maskUtils';
import { parseUrn } from 'utils/urnUtils';
import { toDateTimeString } from 'utils/dateUtils';
import { LoyaltyProgramLogo } from 'components/shared/ui/logos/LoyaltyProgramLogo';
import { DriverData } from 'components/shared/uiModels/driver/driverDataTypes';
import { FC, useMemo } from 'react';
import { useLocale, useTranslations } from 'components/shared/i18n';
import { PhoneType } from 'services/renter/renterReferenceTypes';
import { getItemRates } from 'utils/peoUtils';

type DriverCardProps = {
  driver: DriverData;
  phoneTypeDomain: PhoneType[] | undefined;
  isAdditionalDriver?: boolean;
};

export const DriverCard: FC<DriverCardProps> = ({ driver, phoneTypeDomain, isAdditionalDriver }): JSX.Element => {
  const { t } = useTranslations();
  const { locale } = useLocale();
  const phoneType = useMemo(() => {
    return phoneTypeDomain?.find((phone) => phone.code === driver?.phone?.type)?.name;
  }, [driver?.phone?.type, phoneTypeDomain]);
  const containsDriverAddress = useMemo(() => {
    if (!driver.address) {
      return false;
    }

    let containsAddressInfo = false;
    for (const key in Object.keys(driver.address)) {
      if (Object.values(driver.address)[key].length > 0) {
        containsAddressInfo = true;
        break;
      }
    }

    return containsAddressInfo;
  }, [driver.address]);

  return (
    <Box display={'flex'} flexDirection={'column'} data-testid={'flexiFlow-driver-card'}>
      <Grid container paddingTop={ehiTheme.spacing(1)}>
        {driver?.lastName && (
          <Grid item xs={6} sm={6} data-testid={'driver-contact-flex-flow'}>
            <Caption2 data-testid={'driver-contact-label'}>{t('driver.contact')}</Caption2>
            <Body2 bold data-testid={'driver-name'}>
              {/* Note: get driver profile doesn't return returnWarningFlag, so look for renterWarning object */}
              {driver?.renterWarning && (
                <StyledWarningIcon data-testid='renterWarningIcon' sx={{ paddingTop: ehiTheme.spacing(0.2) }} />
              )}
              {formatName(driver?.lastName, driver?.firstName) ?? '--'}
            </Body2>
            <Body2 data-testid={'driver-phone-number'}>
              {phoneType} {formatPhoneNumber(driver?.phone?.number)}
            </Body2>
            <Body2 data-testid={'driver-email'}>{driver?.email}</Body2>
          </Grid>
        )}
        {containsDriverAddress && (
          <Grid item xs={6} sm={6} data-testid={'driver-address-flex-flow'}>
            <Caption2 data-testid={'driver-address-label'}>{t('driver.address')}</Caption2>
            {driver.address?.lines &&
              driver.address.lines.map((line: string, index: number) => {
                return (
                  <Body2 key={line} data-testid={`driver-address-line-${index + 1}`}>
                    {line}
                  </Body2>
                );
              })}
            <Body2 data-testid={'driver-address-info'}>
              {` ${
                !!driver.address?.city && driver.address?.city.length > 0
                  ? driver.address?.city + ','
                  : driver.address?.city
              } ${driver.address?.countrySubdivisionCode} ${driver.address?.postalCode} ${driver.address?.countryCode}`}
            </Body2>
          </Grid>
        )}
      </Grid>
      <Grid container paddingTop={ehiTheme.spacing(2)}>
        {driver?.legalIdentification && (
          <Grid item xs={6} sm={6} data-testid={'driver-license-flex-flow'}>
            <Caption2 data-testid={'driver-license-label'}>{t('driver.license')}</Caption2>
            <Body2 data-testid={'driver-license-number'}>{mask(driver.legalIdentification.number, 4)}</Body2>
            <Body2 data-testid={'driver-license-subdivision'}>
              {driver.legalIdentification.countrySubdivision
                ? `${parseUrn(driver.legalIdentification.countrySubdivision)}, `
                : ''}
              {parseUrn(driver.legalIdentification.country)}
            </Body2>
            <Body2 display='inline' data-testid={'driver-license-exp-label'}>
              {t('driverSearch.dlExp')}
            </Body2>
            <Body2 display='inline' data-testid={'driver-license-exp-date'}>
              {toDateTimeString(driver.legalIdentification.expirationDate, t('format.date'))}
            </Body2>
            <br />
            <Body2 display='inline' data-testid={'driver-dob-label'}>
              {t('driverSearch.dob')}
            </Body2>
            <Body2 display='inline' data-testid={'driver-dob-date'}>
              {toDateTimeString(driver?.birthInformation?.date, t('format.date'))}
            </Body2>
          </Grid>
        )}
        {driver?.loyaltyType && (
          <Grid item xs={6} sm={6} data-testid={'driver-loyalty-flex-flow'}>
            <Caption2 data-testid={'driver-loyalty-label'}>{t('driver.loyalty')}</Caption2>
            <Body2 data-testid={'driver-loyalty-type'}>{driver.loyaltyProgram?.name}</Body2>
            <Body2 data-testid={'driver-loyalty-logo'}>
              <LoyaltyProgramLogo membershipProgram={driver.loyaltyType} height={24} width={64} />
            </Body2>
            <Body2 data-testid={'driver-loyalty-number'}>{driver.loyaltyNumber}</Body2>
          </Grid>
        )}
        {isAdditionalDriver && (
          <Grid item xs={12}>
            <Body1 data-testid={'driver-fees-label'}>
              <strong>{t('driver.additionalDrivers.driverFees')}</strong>
            </Body1>
            <Body1 data-testid='driver-fees'>
              {driver.driverFees ? getItemRates(driver.driverFees, t, locale) : '--'}
            </Body1>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
