import React, { createContext, useContext, useState } from 'react';
import { AlertDialog } from './AlertDialog';
import { AlertContextProps, AlertDialogProps } from './AlertDialogTypes';
import { Box } from '@mui/material';
import { Body1 } from '@ehi/ui';

export const AlertContext = createContext<AlertContextProps>({
  showAlert: () => {
    // do nothing
  },
});

export const useAlert = (): AlertContextProps => useContext(AlertContext);

export const AlertProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [alert, setAlert] = useState<AlertDialogProps | null>(null);
  const showAlert = (alert: AlertDialogProps) => {
    setAlert(alert);
  };
  const hideAlert = () => {
    if (alert) {
      alert.onClose();
      setAlert(null);
    }
  };
  const content = (
    <Box minWidth='20rem' data-testid='alert-content'>
      {alert?.subtitle && <Body1 bold>{alert.subtitle}</Body1>}
      {alert?.description}
    </Box>
  );
  return (
    <AlertContext.Provider value={{ showAlert }}>
      {children}
      {alert && (
        <AlertDialog
          open={alert.open}
          title={alert.title}
          description={content}
          onClose={hideAlert}
          moreMenu={alert.moreMenu}
          primaryActionText={alert.primaryActionText || 'Ok'}
          secondaryActionText={alert.hideSecondaryActionText ? '' : alert.secondaryActionText || 'Cancel'}
          onPrimaryAction={alert.onPrimaryAction}
          onSecondaryAction={alert.onSecondaryAction}></AlertDialog>
      )}
    </AlertContext.Provider>
  );
};
