import { DateTime } from 'luxon';
import { VehicleContent } from 'services/vehicleContent/vehicleContentTypes';

export interface QuickResValues {
  [QuickResFields.CurrentLocationTimezone]: string;
  [QuickResFields.CurrentLocationUrn]: string;
  [QuickResFields.CurrentLocationTime]: DateTime | '';
  [QuickResFields.StartDate]: DateTime | '';
  [QuickResFields.StartTime]: DateTime | '';
  [QuickResFields.StartDateTime]: DateTime | '';
  [QuickResFields.ReturnDate]: DateTime | '';
  [QuickResFields.ReturnTime]: DateTime | '';
  [QuickResFields.ReturnDateTime]: DateTime | '';
  [QuickResFields.VehicleClass]: string;
  [QuickResFields.LastName]: string;
  [QuickResFields.FirstName]: string;
  [QuickResFields.Age]: string;
  [QuickResFields.ContactType]: string;
  [QuickResFields.CountryCode]: string;
  [QuickResFields.Phone]: string;
  [QuickResFields.Email]: string;
}

export enum QuickResFields {
  // Set Location
  CurrentLocationTimezone = 'currentLocationTimezone',
  CurrentLocationUrn = 'currentLocationUrn',
  CurrentLocationTime = 'currentLocationTime',

  // Rental Start
  StartDate = 'startDate',
  StartTime = 'startTime',
  StartDateTime = 'startDateTime',

  // Rental Return
  ReturnDate = 'returnDate',
  ReturnTime = 'returnTime',
  ReturnDateTime = 'returnDateTime',

  // Vehicle
  VehicleClass = 'vehicleClass',

  // Driver
  LastName = 'lastName',
  FirstName = 'firstName',
  Age = 'age',

  // Contact
  ContactType = 'contactType',
  CountryCode = 'countryCode',
  Phone = 'phone',
  Email = 'email',
}

export interface QuickResVehicleProps {
  vehicleContent: VehicleContent | undefined;
}
