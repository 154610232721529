import { useCallback } from 'react';
import {
  clearPersistedEditorSessionData,
  clearPersistedReservationData,
} from 'components/shared/preprocessor/ReservationSessionHelper';
import { removeItem, WORKFLOW_ID_KEY } from 'utils/localStorageUtils';
import { useAppDispatch } from 'redux/hooks';
import { resetReservationState } from 'redux/slices/booking/bookingEditorSlice';
import { ReservationSessionHelperHook } from './reservationDataTypes';

export const useReservationSessionHelper = (): ReservationSessionHelperHook => {
  const dispatch = useAppDispatch();

  const clearReservationSession = useCallback(() => {
    clearPersistedReservationData();
    dispatch(resetReservationState(undefined));
    removeItem(WORKFLOW_ID_KEY);
  }, [dispatch]);

  const clearEditorSession = useCallback(() => {
    clearPersistedEditorSessionData();
    dispatch(resetReservationState(undefined));
    removeItem(WORKFLOW_ID_KEY);
  }, [dispatch]);

  return { clearReservationSession, clearEditorSession };
};
