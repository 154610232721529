import {
  AddressType,
  AddressV2,
  BranchSearchResult,
  FulfillmentBookingSystems,
  PhoneType,
  PhoneV2,
} from 'services/location/locationTypes';
import { formatPhoneNumber } from 'utils/phoneNumberUtils';
import { BranchLookupCard } from 'components/shared/uiModels/branchLookup/branchLookupDataTypes';
import { extractCountryCode, isNaCountryCode } from 'utils/locationUtils';

const getAddressLines = (address: AddressV2 | undefined) => {
  const addressLines = [];
  address?.line1 && addressLines.push(address.line1);
  address?.line2 && addressLines.push(address.line2);

  return addressLines;
};

const getBranchPhysicalAddress = (addresses: AddressV2[] | undefined) => {
  return addresses?.filter((address) => address.type === AddressType.PHYSICAL && address.default)?.[0];
};

const getPhoneNumber = (PhoneNumbers: PhoneV2[] | undefined): string | undefined => {
  const officePhoneNumber = PhoneNumbers?.filter((phone) => phone.type === PhoneType.OFFICE && phone.default);
  return formatPhoneNumber(officePhoneNumber?.[0]?.number);
};

export const transformBranchLookupData = (branches: BranchSearchResult[] | undefined): BranchLookupCard[] => {
  if (!branches) {
    return [];
  }
  return branches?.map((branch: BranchSearchResult) => {
    const address = getBranchPhysicalAddress(branch.addresses);
    const isOdyssey = branch.operationalModel?.rentalConfiguration?.bookingSystem === FulfillmentBookingSystems.ODYSSEY;
    const isNonUSOrCANBranch = !isNaCountryCode(extractCountryCode(branch.addresses));

    return {
      address: {
        lines: getAddressLines(address),
        city: address?.city ?? '',
        countrySubdivisionAbbreviation: address?.countrySubdivision?.abbreviation ?? '',
        countryCode: address?.country?.code ?? '',
        postalCode: address?.postalCode?.code ?? '',
      },
      phone: getPhoneNumber(branch.phoneNumbers),
      geoLocation: { lat: branch.geoLocation?.latitude ?? 0, lng: branch.geoLocation?.longitude ?? 0 },
      description: branch.description,
      stationId: branch.stationId,
      brand: branch.brand,
      airport: branch.airport,
      branchUrn: branch.urn,
      isDisabled: !isOdyssey || isNonUSOrCANBranch,
    };
  });
};
