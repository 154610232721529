import { FC } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { QuickResFields } from 'components/quickRes/QuickResTypes';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { FormTextField } from 'components/shared/forms/FormTextField';
import { SelectField } from 'components/shared/forms/SelectField';
import { PhoneTextField } from 'components/shared/forms/PhoneTextField';
import { StyledDivider } from 'components/quickRes/QuickRes.styles';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { useFormContext, useWatch } from 'react-hook-form';
import { updateContactInformation } from 'services/booking/bookingService';
import { usePhoneTypesQuery } from 'services/renter/renterReferenceQueries';
import { CountrySelectField } from 'components/shared/forms/CountrySelectField';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';

const QuickResContact: FC = () => {
  const { t } = useTranslations();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { data: phoneTypeDomain } = usePhoneTypesQuery();
  const contactTypes = phoneTypeDomain?.map((phone) => ({ label: phone.name || '', value: phone.code || '' }));
  const {
    getFieldState,
    trigger,
    setError,
    formState: { errors },
  } = useFormContext();
  const [contactType, countryCode, phone, email] = useWatch({
    name: [QuickResFields.ContactType, QuickResFields.CountryCode, QuickResFields.Phone, QuickResFields.Email],
  });

  console.log(contactType, countryCode);
  const handleOnBlur = async (field: string) => {
    await trigger(field);
    if (getFieldState(field).isDirty && !errors[QuickResFields.Phone] && !errors[QuickResFields.Email]) {
      const payload = phone?.length
        ? {
            phone: { number: phone },
            email: email,
          }
        : {
            email: email,
          };
      const result = await updateAndRefresh(() => updateContactInformation(bookingEditorId, payload));
      if (result?.errors?.length) {
        setError(field, { message: result?.errors?.[0]?.localizedMessage });
      }
    }
  };

  return (
    <GridContainer>
      <GridItem sm={12}>
        <h3 data-testid={'res-contact'}>{t('driver.contact')}</h3>
      </GridItem>
      <GridItem sm={4}>
        <SelectField
          name={QuickResFields.ContactType}
          label={t('driver.type')}
          data-testid={QuickResFields.ContactType}
          options={contactTypes || []}
          hasNoneOption={false}
        />
      </GridItem>
      <GridItem sm={4}>
        <CountrySelectField
          name={QuickResFields.CountryCode}
          label={t('location.countryCode')}
          data-testid={QuickResFields.CountryCode}
        />
      </GridItem>
      <GridItem sm={4}>
        <PhoneTextField
          name={QuickResFields.Phone}
          label={t('driver.phone')}
          data-testid={QuickResFields.Phone}
          onBlur={() => handleOnBlur(QuickResFields.Phone)}
        />
      </GridItem>
      <GridItem sm={6}>
        <FormTextField
          name={QuickResFields.Email}
          label={t('driver.email')}
          onBlur={() => handleOnBlur(QuickResFields.Email)}
        />
      </GridItem>
      <GridItem xs={12} sm={12}>
        <StyledDivider />
      </GridItem>
    </GridContainer>
  );
};
export default QuickResContact;
