import { ReservationSummaryCard } from 'components/resSummary/ReservationSummaryCard';
import CardContent from '@mui/material/CardContent';
import { Grid, IconButton } from '@mui/material';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { Body1, Body2 } from '@ehi/ui';
import { useBranchInfoByUrnQuery } from 'services/location/locationQueries';
import { getDaysDiff, toDateTimeString, datesAreSelected } from 'utils/dateUtils';
import { useTranslations } from 'components/shared/i18n';
import { ArrowRightAlt } from '@mui/icons-material';
import { useAppSelector } from 'redux/hooks';
import { selectOneWay, selectPickup, selectReturn } from 'redux/selectors/bookingEditor';
import { RouterPaths } from 'app/router/RouterPaths';
import { mapLoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewUtils';

export const WhenAndWhereSummary = (): JSX.Element => {
  const { t } = useTranslations();
  const pickupData = useAppSelector(selectPickup);
  const returnData = useAppSelector(selectReturn);

  function dateDisplay() {
    if (haveDatesBeenSelected) {
      return (
        <div data-testid='dateDisplay'>
          <Caption2>{t('whenWhere.rentalStart')}</Caption2>
          <div>
            <Body1 bold display='inline' noWrap={true} data-testid='date'>
              {formattedPickupDate}
            </Body1>
          </div>
        </div>
      );
    }
    return (
      <div>
        <Body1 data-testid='noDatesMessage'>{t('whenWhere.noDatesSelected')}</Body1>
      </div>
    );
  }

  const {
    data: pickupBranch,
    isFetching: isFetchingPickupBranch,
    isError: isPickupBranchError,
  } = useBranchInfoByUrnQuery(pickupData?.branch ?? '');
  const {
    data: returnBranch,
    isFetching: isFetchingReturnBranch,
    isError: isReturnBranchError,
  } = useBranchInfoByUrnQuery(returnData?.branch ?? '');
  const isFetching = isFetchingPickupBranch || isFetchingReturnBranch;
  const isError = isPickupBranchError || isReturnBranchError;

  const formattedPickupDate = toDateTimeString(pickupData?.dateTime, t('format.shortDateTime'), pickupBranch?.timezone);
  const formattedReturnDate = toDateTimeString(returnData?.dateTime, t('format.shortDateTime'), returnBranch?.timezone);
  const isOneWay = useAppSelector(selectOneWay);
  const lengthOfRental = getDaysDiff(pickupData?.dateTime, returnData?.dateTime);
  const haveDatesBeenSelected = datesAreSelected(pickupData?.dateTime, returnData?.dateTime);

  return (
    <ReservationSummaryCard
      title={t('reservationSummary.whenWhere')}
      loading={mapLoadingState(isFetching, isError)}
      path={RouterPaths.WhenAndWhere}
      data-testid='whenAndWhereSummaryCard'>
      <CardContent data-testid='content'>
        <Grid container direction={'column'}>
          <Grid item xs width={'100%'} data-testid='pickupContent'>
            {dateDisplay()}
            <Body2 data-testid='locationData' whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'}>
              <span data-testid='locationInfo'>
                {pickupBranch?.stationId} {pickupBranch?.operationalDescription}
              </span>
            </Body2>
          </Grid>
          <Grid item xs width={'100%'} data-testid='returnContent'>
            {haveDatesBeenSelected && (
              <>
                <Caption2>
                  {t('whenWhere.rentalReturn')}
                  {isOneWay && (
                    <IconButton data-testid='oneWayIcon'>
                      <ArrowRightAlt fontSize='small' color='secondary' />
                    </IconButton>
                  )}
                </Caption2>
                <div>
                  <Body1 data-testid='date' bold display='inline' noWrap={true}>
                    {formattedReturnDate}
                  </Body1>
                  <Body2 data-testid='lengthOfRental' display={'inline'}>
                    {` (${lengthOfRental}${lengthOfRental === 1 ? t('whenWhere.day') : t('whenWhere.days')})`}
                  </Body2>
                </div>
              </>
            )}
            <Body2 data-testid='locationData' whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'}>
              <span data-testid='locationInfo'>
                {returnBranch?.stationId} {returnBranch?.operationalDescription}
              </span>
            </Body2>
          </Grid>
        </Grid>
      </CardContent>
    </ReservationSummaryCard>
  );
};
