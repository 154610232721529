import { parseUrn } from 'utils/urnUtils';
import { RateSource } from 'services/booking/bookingTypes';
import { useAppSelector } from 'redux/hooks';
import { selectRateSource } from 'redux/selectors/bookingEditor';
import { useBusinessAccountQuery } from 'services/businessAccount/accountQueries';
import { UseAccountDetailsReturn } from './businessAccountTypes';

export const useAccountDetails = (): UseAccountDetailsReturn => {
  const rateSource: RateSource | undefined = useAppSelector(selectRateSource);
  const accountNumber = parseUrn(rateSource?.type === 'NEGOTIATED' ? rateSource?.account : undefined);

  const {
    data: accountDetails,
    isLoading,
    error: accountErrors,
  } = useBusinessAccountQuery(accountNumber, {
    enabled: !!accountNumber,
  });

  const hasRetailRatePlan = rateSource?.type === 'RETAIL' && !!rateSource?.rateProduct;

  return {
    accountDetails,
    isLoading: !!accountNumber && isLoading,
    hasRetailRatePlan,
    errors: accountErrors,
  };
};
