import { OperationalLocationType } from 'services/location/locationReferenceTypes';
import { AirportBranchSearchCriteria, BranchDetailsSearchCriteria } from 'services/location/locationTypes';

export enum DisplayedLocationTypes {
  AIRPORT = 'AIRPORT',
  DEALERSHIP = 'DEALERSHIP',
  HOME_CITY = 'HOME_CITY', // is not currently in response
  PORT = 'PORT',
  TRAIN_OR_SUBWAY = 'TRAIN_OR_SUBWAY',
  TRUCK_RENTAL = 'TRUCK_RENTAL',
}

const dropdownLocationTypes = [
  DisplayedLocationTypes.AIRPORT,
  DisplayedLocationTypes.DEALERSHIP,
  DisplayedLocationTypes.HOME_CITY,
  DisplayedLocationTypes.PORT,
  DisplayedLocationTypes.TRAIN_OR_SUBWAY,
  DisplayedLocationTypes.TRUCK_RENTAL,
];

export function filterLocationTypes(types: OperationalLocationType[]) {
  return types.filter((item) => dropdownLocationTypes.includes(item.code as DisplayedLocationTypes));
}

export function getLocationTypeUrns(types: OperationalLocationType[]) {
  const locationUrns: string[] = [];
  types.map((item) => {
    locationUrns.push(item.urn);
  });

  return locationUrns;
}

export enum DistanceValues {
  TEN = '10',
  TWENTY_FIVE = '25',
  FIFTY = '50',
  HUNDRED = '100',
}

export const distances = (distanceText: string) => [
  { name: '10 '.concat(distanceText), value: DistanceValues.TEN },
  { name: '25 '.concat(distanceText), value: DistanceValues.TWENTY_FIVE },
  { name: '50 '.concat(distanceText), value: DistanceValues.FIFTY },
  { name: '100 '.concat(distanceText), value: DistanceValues.HUNDRED },
];

export const brands = [
  { title: 'Alamo', key: 'Alamo' },
  { title: 'Enterprise', key: 'Enterprise' },
  { title: 'National', key: 'National' },
];

export const locationTypes = [
  { title: 'Airport', key: 'Airport' },
  { title: 'Dealership', key: 'Dealership' },
  { title: 'HomeCity', key: 'HomeCity' },
  { title: 'Port', key: 'Port' },
  { title: 'Train', key: 'Train' },
  { title: 'Truck', key: 'Truck' },
];

const OPERATIONAL_LOCATION_TYPE_DEALERSHIP = 'urn:com.ehi:xqa:reference:location:operationalLocationType:DEALERSHIP';
const OPERATIONAL_LOCATION_TYPE_TRAIN_OR_SUBWAY =
  'urn:com.ehi:xqa:reference:location:operationalLocationType:TRAIN_OR_SUBWAY';
const OPERATIONAL_LOCATION_TYPE_TRUCK_RENTAL =
  'urn:com.ehi:xqa:reference:location:operationalLocationType:TRUCK_RENTAL';
const OPERATIONAL_LOCATION_TYPE_PORT = 'urn:com.ehi:xqa:reference:location:operationalLocationType:PORT';
const OPERATIONAL_LOCATION_TYPE_AIRPORT = 'urn:com.ehi:xqa:reference:location:operationalLocationType:AIRPORT';

const ALL_LOCATION_TYPES_EXCEPT_HOME_CITY_AND_AIRPORT = [
  OPERATIONAL_LOCATION_TYPE_DEALERSHIP,
  OPERATIONAL_LOCATION_TYPE_TRAIN_OR_SUBWAY,
  OPERATIONAL_LOCATION_TYPE_TRUCK_RENTAL,
  OPERATIONAL_LOCATION_TYPE_PORT,
];

const ALL_LOCATION_TYPES_WITH_AIRPORT = [
  ...ALL_LOCATION_TYPES_EXCEPT_HOME_CITY_AND_AIRPORT,
  OPERATIONAL_LOCATION_TYPE_AIRPORT,
];

export const adjustSearchRequestForLocationTypes = (
  searchRequestObject: BranchDetailsSearchCriteria,
  types: Array<{ code: string }>
): void => {
  const isHomeCitySelected = types.some((option) => option.code === DisplayedLocationTypes.HOME_CITY);
  const isAirportSelected = types.some((option) => option.code === DisplayedLocationTypes.AIRPORT);

  if (isHomeCitySelected && isAirportSelected) {
    if (types.length === 2) {
      searchRequestObject.operationalLocationTypes = ALL_LOCATION_TYPES_WITH_AIRPORT;
    }
  } else if (isHomeCitySelected && !isAirportSelected) {
    if (types.length === 1) {
      searchRequestObject.airport = AirportBranchSearchCriteria.EXCLUDE_AIRPORTS;
      searchRequestObject.operationalLocationTypes = ALL_LOCATION_TYPES_EXCEPT_HOME_CITY_AND_AIRPORT;
    }
  } else if (!isHomeCitySelected && !isAirportSelected) {
    searchRequestObject.airport = AirportBranchSearchCriteria.EXCLUDE_AIRPORTS;
  }
};
