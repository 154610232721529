import { useCallback } from 'react';
import { WhenAndWhereFields, WhenAndWhereValues } from 'components/flexFlow/whenAndWhere/WhenAndWhereTypes';
import { updatePickupInformation, updateReturnInformation } from 'services/booking/bookingService';
import { useRefreshEditor } from 'hooks/bookingEditor/useRefreshEditor';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import { RouterPaths } from 'app/router/RouterPaths';
import { useTranslations } from 'components/shared/i18n';
import { useNavigate } from 'react-router-dom';
import { ServiceResultType } from 'services/types/ServiceResultTypes';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';

export type SavePickupReturn = {
  save: (data: WhenAndWhereValues) => Promise<ServiceResultType>;
  handleErrors: (errors: ResponseMessage[]) => void;
};

export const useSaveWhenAndWhere = (): SavePickupReturn => {
  const { t } = useTranslations();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const { refreshEditor } = useRefreshEditor();
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();

  const handleErrors = useCallback(
    (errors: ResponseMessage[]) => {
      showAlert({
        title: t('error.error'),
        description: t('error.editorError', {
          error: errors?.[0].localizedMessage || '',
        }),
        primaryActionText: t('error.reload'),
        secondaryActionText: t('error.home'),
        open: true,
        onPrimaryAction: () => {
          return refreshEditor(bookingEditorId);
        },
        onSecondaryAction: () => {
          navigate(RouterPaths.Search);
        },
        onClose: () => {
          return;
        },
      });
    },
    [bookingEditorId, navigate, refreshEditor, showAlert, t]
  );

  const save = useCallback(
    async (values: WhenAndWhereValues) => {
      const pickUpLocation = values[WhenAndWhereFields.StartLocationUrn];
      const returnLocation = values[WhenAndWhereFields.ReturnLocationUrn];
      const startDateTime = values[WhenAndWhereFields.StartDateTime];
      const returnDateTime = values[WhenAndWhereFields.ReturnDateTime];

      let pickUpInfo = {
        branch: pickUpLocation,
      };
      pickUpInfo = { ...pickUpInfo, ...(startDateTime ? { dateTime: startDateTime } : {}) };

      let returnInfo = {
        branch: returnLocation,
      };
      returnInfo = { ...returnInfo, ...(returnDateTime ? { dateTime: returnDateTime } : {}) };

      const { errors } = await updateAndRefresh(async () => {
        await updatePickupInformation(bookingEditorId, pickUpInfo);

        await updateReturnInformation(bookingEditorId, returnInfo);
      });

      if (errors) {
        handleErrors(errors);
      }
      return { success: !!errors, errors: errors };
    },
    [bookingEditorId, handleErrors, updateAndRefresh]
  );

  return { save, handleErrors };
};
