import { FC, useState } from 'react';
import { EhiButton } from '@ehi/ui';
import { useTranslations } from 'components/shared/i18n';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from 'app/router/RouterPaths';
import { QuickResFields, QuickResValues } from './QuickResTypes';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import { FormProvider, useForm } from 'react-hook-form';
import { GridContainer } from 'components/shared/ui/styles/Grid.styles';
import QuickResDateTime from 'components/quickRes/QuickResDateTime';
import QuickResRateSource from 'components/quickRes/QuickResRateSource';
import QuickResVehicle from 'components/quickRes/QuickResVehicle';
import QuickResDriver from 'components/quickRes/QuickResDriver';
import QuickResContact from 'components/quickRes/QuickResContact';
import { quickResInitialValues, quickResValidationSchema } from 'components/quickRes/quickResUtils';
import CustomerHeader from 'components/customerHeader/CustomerHeader';
import { generateSearchParams } from 'utils/routing/urlUtils';
import { InternalTransactionParams } from 'utils/routing/InternalTransactionParams';
import { TransactionTypes } from 'utils/routing/TransactionTypes';
import { LoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewTypes';
import { useStartReservationSession } from 'components/shared/preprocessor/useStartReservationSession';
import { logError } from 'components/shared/logger/splunkLogger';
import { ErrorSeverity } from '@ehi/analytics';
import { loadCounterCookie, loadEhiLocationCookie } from '@ehi/location';
import NetworkError from 'components/shared/errors/NetworkError';
import { useEffectOnlyOnce } from 'hooks/useEffectOnlyonce';
import { saveToReservation } from 'services/booking/bookingService';
import { selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { useAppSelector } from 'redux/hooks';
import { getLocationHeaderFromUrl } from 'components/shared/preprocessor/ReservationSessionHelper';
import { QuickResCancelModal } from './QuickResCancelModal';
import { safelyCatchError } from 'utils/errorUtils';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useRefreshEditor } from 'hooks/bookingEditor/useRefreshEditor';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';
import { useReservationSessionHelper } from 'components/shared/preprocessor/useReservationSessionHelper';
import { useVehicleContentQuery } from 'services/vehicleContent/vehicleContentQueries';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { useLocationQuery } from 'services/location/locationQueries';
import { useDateTimeFormater } from 'utils/routing/useDatetimeFormater';
import { omit } from 'lodash';

export type CreateResProps = {
  open: boolean;
  onSaveAndExit: (resNum: string) => void;
  onSaveAndContinue?: (resNum: string) => void;
  onCancel?: () => void;
  onError?: (error: unknown) => void;
};

const QuickResModal: FC<CreateResProps> = ({ open, onSaveAndExit, onSaveAndContinue }) => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const resolver = useYupValidationResolver(quickResValidationSchema(t));
  const { startOpenEditorSession } = useStartReservationSession();
  const { getLocalizedDateTime } = useDateTimeFormater();
  const cookieLocation = loadEhiLocationCookie();
  const { data: locationQuery } = useLocationQuery(cookieLocation?.peoplesoftId ?? '');
  const counterCookie = loadCounterCookie();
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.SUCCESS);
  const formMethods = useForm<QuickResValues>({
    resolver: resolver,
    defaultValues: quickResInitialValues(locationQuery?.location, getLocalizedDateTime),
  });

  const formFieldValues = omit(formMethods.watch(), [
    QuickResFields.CurrentLocationTime,
    QuickResFields.CurrentLocationTimezone,
    QuickResFields.CurrentLocationUrn,
  ]);
  const formFieldsHasValues = formFieldValues ? Object.values(formFieldValues).some((value) => !!value) : false;
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const { refreshEditor } = useRefreshEditor();
  const { showAlert } = useAlert();
  const { clearEditorSession } = useReservationSessionHelper();
  const { data: vehicleContent, isFetching: isVehicleContentLoading } = useVehicleContentQuery();

  const handleCancel = () => {
    setShowCancelDialog(true);
  };

  const handleSaveAndExit = async () => {
    try {
      setLoadingState(LoadingState.LOADING);
      const { headers } = await saveToReservation(bookingEditorId, { skipValidation: true });
      const resNum = getLocationHeaderFromUrl(headers?.location);
      clearEditorSession();
      onSaveAndExit(resNum);
    } catch (error) {
      handleSaveError(error);
    }
  };

  const handleSaveError = (error: unknown) => {
    const ehiErrorsResponse = safelyCatchError(error);
    const responseMessages = ehiErrorsResponse?.errors;
    if (ehiErrorsResponse?.errors) {
      const errorList = responseMessages?.length ? (
        responseMessages.map((err: ResponseMessage, index: number) => (
          <li key={index}>{err?.localizedMessage ?? t('error.unknownError')}</li>
        ))
      ) : (
        <li>{t('error.unknownError')}</li>
      );

      showAlert({
        title: t('error.error'),
        description: <>{errorList}</>,
        primaryActionText: t('error.reload'),
        secondaryActionText: t('error.home'),
        open: true,
        onClose: () => {
          return;
        },
        onPrimaryAction: () => {
          return refreshEditor(bookingEditorId);
        },
        onSecondaryAction: () => {
          navigate(RouterPaths.Search);
        },
      });
      setLoadingState(LoadingState.SUCCESS);
    } else {
      setLoadingState(LoadingState.ERROR);
    }
  };

  const handleSaveAndContinue = async () => {
    try {
      setLoadingState(LoadingState.LOADING);
      const { headers } = await saveToReservation(bookingEditorId, { skipValidation: false });
      const resNum = getLocationHeaderFromUrl(headers?.location);
      setLoadingState(LoadingState.SUCCESS);
      clearEditorSession();
      navigate(
        `${RouterPaths.PreProcessor}?${generateSearchParams({
          [InternalTransactionParams.TransactionType]: TransactionTypes.Modify,
          [InternalTransactionParams.Res]: resNum,
        })}`
      );
      onSaveAndContinue?.(resNum);
    } catch (error) {
      handleSaveError(error);
    }
  };

  const createEditorId = async () => {
    const { errors } = await startOpenEditorSession(TransactionTypes.CreateQuickRes);
    if (!errors?.length) {
      setLoadingState(LoadingState.SUCCESS);
    } else {
      logError({
        error: {
          message: 'Error Creating Reservation Editor',
          supportInformation: {
            transactionType: TransactionTypes.CreateQuickRes,
            location: cookieLocation,
            counter: counterCookie?.counterId,
            serviceError: errors,
          },
        },
        severity: ErrorSeverity.Fatal,
      });
      setLoadingState(LoadingState.ERROR);
    }
  };

  useEffectOnlyOnce(() => {
    createEditorId();
  });

  return (
    <FormProvider {...formMethods}>
      <Dialog
        data-testid={'quick-res-modal'}
        contentPadding={0}
        open={open}
        title={t('common.newReservation')}
        a11yKey='content'
        showDividers
        maxWidth={'md'}
        fullWidth
        titleActions={[
          <EhiButton
            data-testid={'fullReservation'}
            key='res'
            disabled={formFieldsHasValues}
            onClick={() =>
              navigate(
                `${RouterPaths.PreProcessor}?${generateSearchParams({
                  [InternalTransactionParams.TransactionType]: TransactionTypes.CreateFullRes,
                })}`
              )
            }>
            {t('quickReservation.fullReservation')}
          </EhiButton>,
        ]}
        actions={{
          tertiaryAction: {
            label: t('common.cancel'),
            onClick: handleCancel,
          },
          secondaryAction: {
            label: t('quickReservation.saveAndExit'),
            onClick: handleSaveAndExit,
            overrideButtonProps: {
              disabled: !formMethods.formState.isDirty && !formMethods.formState.isValid,
            },
          },
          primaryAction: {
            label: t('quickReservation.saveAndContinue'),
            onClick: handleSaveAndContinue,
            overrideButtonProps: {
              disabled: !formMethods.formState.isDirty && !formMethods.formState.isValid,
            },
          },
        }}
        additionalHeader={<CustomerHeader />}>
        <GridContainer style={{ padding: '0rem 1rem' }}>
          <QuickResDateTime />
          <QuickResRateSource />
          <QuickResVehicle vehicleContent={vehicleContent} />
          <QuickResDriver />
          <QuickResContact />
          {showCancelDialog && (
            <QuickResCancelModal open={showCancelDialog} onClose={() => setShowCancelDialog(false)} />
          )}
        </GridContainer>
        {loadingState === LoadingState.ERROR && <NetworkError />}
        <ProgressOverlay
          inProgress={loadingState === LoadingState.LOADING || isVehicleContentLoading}
          fullPageSpinner
        />
      </Dialog>
    </FormProvider>
  );
};

export default QuickResModal;
