import { AutoCompleteOption } from 'components/shared/forms/AutoCompleteMultipleField';
import { BranchLookupCard } from 'components/shared/uiModels/branchLookup/branchLookupDataTypes';
import { TFunction } from 'i18next';
import { OperationalLocationType } from 'services/location/locationReferenceTypes';
import { Brand } from 'services/location/locationTypes';
import { enUS } from '@ehi/location';
import { getCurrentIso3Locale } from 'components/shared/i18n/locales';

export type BranchSearchResult = {
  branches: BranchLookupCard[];
  searchInputText: string;
};

export enum SearchByType {
  Keyword = 'keyword',
  PostalCode = 'postalCode',
}

export const HOME_CITY_URN = 'urn:com.ehi:xqa:reference:location:operationalLocationType:HOME_CITY';
export const HOME_CITY = 'HOME_CITY';
export const HOME_CITY_DESCRIPTION = 'Located in a home city';

export type DistanceType = { name: string; value: string };

export interface BranchLookupValues {
  [BranchLookupFields.SearchBy]: string;
  [BranchLookupFields.Country]: string;
  [BranchLookupFields.Groups]: AutoCompleteOption[];
  [BranchLookupFields.Brands]: string[];
  [BranchLookupFields.LocationTypes]: OperationalLocationType[];
  [BranchLookupFields.Distance]: DistanceType;
  [BranchLookupFields.IsListButtonSelected]: boolean;
  [BranchLookupFields.IsMapButtonSelected]: boolean;
  [BranchLookupFields.SearchInputValue]: string;
  [BranchLookupFields.SelectedBranch]: BranchLookupCard | undefined;
}

export enum BranchLookupFields {
  SearchBy = 'searchBy',
  Country = 'countrySelect',
  Groups = 'groupsFilter',
  Brands = 'brandFilter',
  LocationTypes = 'locationTypesFilter',
  Distance = 'distanceFilter',
  HideDistance = 'hideDistance',
  IsListButtonSelected = 'isListSelected',
  IsMapButtonSelected = 'isMapSelected',
  SearchInputValue = 'searchInputValue',
  SelectedBranch = 'selectedBranch',
}
export const createHomeCityLocationType = (t: TFunction<'translation'>) => ({
  urn: HOME_CITY_URN,
  code: HOME_CITY,
  locale: getCurrentIso3Locale() ?? enUS.iso3Locale,
  name: t('whenWhere.homeCity').toUpperCase(),
  description: HOME_CITY_DESCRIPTION,
});
export const getBranchLookupInitialValues = (
  currentLocation: string | undefined,
  t: TFunction<'translation'>,
  locationTypes: OperationalLocationType[] = []
): BranchLookupValues => {
  return {
    [BranchLookupFields.SearchBy]: SearchByType.Keyword,
    [BranchLookupFields.Country]: currentLocation ?? '',
    [BranchLookupFields.Groups]: [],
    [BranchLookupFields.LocationTypes]: locationTypes,
    [BranchLookupFields.Brands]: [Brand.ENTERPRISE, Brand.NATIONAL, Brand.ALAMO],
    [BranchLookupFields.Distance]: { name: `25 ${t('location.miles')}`, value: '25' },
    [BranchLookupFields.IsListButtonSelected]: true,
    [BranchLookupFields.IsMapButtonSelected]: false,
    [BranchLookupFields.SearchInputValue]: '',
    [BranchLookupFields.SelectedBranch]: undefined,
  };
};

export interface RentalBrand {
  urn: string;
  code: string;
  locale: string;
  name: string;
  legacyCode: string;
}
