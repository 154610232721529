import { styled, Theme } from '@mui/material';
import { GridItem } from 'components/shared/ui/styles/Grid.styles';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';

export const StyledDivider = styled(EhiDivider)(({ theme }: { theme: Theme }) => ({
  margin: theme.spacing(2, 0),
}));

export const StyledGridItem = styled(GridItem)`
  padding-left: 0 !important;
`;
