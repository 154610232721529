import { TransactionTypes } from 'utils/routing/TransactionTypes';
import { EhiLocation } from '@ehi/location';

export const RESERVATION_SESSION_STORAGE_KEY = 'RESERVATION_SESSION_STORAGE_KEY';
export const EDITOR_SESSION_STORAGE_KEY = 'EDITOR_SESSION_STORAGE_KEY';

export type BaseSessionData = {
  transactionType: TransactionTypes;
  counter?: string;
  location?: EhiLocation;
  // use this for external app navigation
  returnUrl?: string;
};

// EditorSessionData is being used for create quick/full res
export type EditorSessionData = BaseSessionData & {
  editorId: string;
  resNumber?: string;
};

// ReservationSessionData is being used for View Reservation
export type ReservationSessionData = BaseSessionData & {
  resNumber: string;
  editorId?: never;
};

export interface ReservationSessionHelperHook {
  clearReservationSession: () => void;
  clearEditorSession: () => void;
}
