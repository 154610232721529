import { Body2, ehiTheme } from '@ehi/ui';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { FlexFlowFab } from 'components/shared/flexiFlow/FlexiFlowMenu.styles';
import { useTranslations } from 'components/shared/i18n';

export const FlexFlowActionButton = (): JSX.Element => {
  const { t } = useTranslations();

  return (
    <FlexFlowFab color='primary' variant={'extended'} data-testid={'flex-flow-action-button'}>
      <PostAddIcon style={{ paddingRight: ehiTheme.spacing(0.5) }} />
      <Body2 id={'floating-action-button'} fontWeight={500}>
        {t('common.create')}
      </Body2>
    </FlexFlowFab>
  );
};
