import { EmptyView } from './EmptyView';
import { useForm } from 'react-hook-form';
import { EquipmentFormModel } from 'components/flexFlow/peo/peoTypes';
import { SavingFormProvider } from 'context/saveOnNavigate/SavingFormProvider';
import { EquipmentList } from './EquipmentList';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { useSaveEquipment } from './useSaveEquipment';

export const EquipmentTab = () => {
  const { saveEquipmentAndRefreshEditor, equipmentValues } = useSaveEquipment();
  const formMethods = useForm({
    defaultValues: equipmentValues,
  });

  const handleSubmit = async (equipmentModel: EquipmentFormModel) => {
    return saveEquipmentAndRefreshEditor(equipmentModel);
  };

  return (
    <SavingFormProvider {...formMethods} submitFn={handleSubmit}>
      {equipmentValues?.items?.length > 0 ? <EquipmentList /> : <EmptyView />}
      <ProgressOverlay inProgress={formMethods.formState.isSubmitting} />
    </SavingFormProvider>
  );
};
