import { FC, useCallback, useState } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import {
  ButtonContainer,
  HeaderContainer,
  StyledDistanceMenu,
  StyledFilterFormControl,
} from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookup.styles';
import { IconButton, InputLabel, Radio } from '@mui/material';
import { useTranslations } from 'components/shared/i18n';
import { Controller, useFormContext } from 'react-hook-form';
import { BranchLookupFields } from './BranchLookupTypes';
import { EhiButton, ehiTheme, H6 } from '@ehi/ui';
import CloseIcon from '@mui/icons-material/Close';
import { distances, DistanceValues } from 'utils/branchLookupUtils';

export const DistanceDropDown: FC = () => {
  const { t } = useTranslations();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const distanceOptions = distances(t('location.miles'));
  const { control, watch, setValue, resetField } = useFormContext();
  const [distance] = watch([BranchLookupFields.Distance]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
  }, []);

  return (
    <StyledFilterFormControl variant='outlined'>
      <InputLabel>{t('location.distance')}</InputLabel>
      <Controller
        control={control}
        name={BranchLookupFields.Distance}
        render={() => (
          <Select
            labelId='distance-label-id'
            data-testid='distanceDropdown'
            label={t('location.distance')}
            style={{ borderRadius: 33 }}
            open={open}
            onClick={handleClick}
            onClose={handleClose}
            MenuProps={{
              anchorOrigin: {
                horizontal: 'center',
                vertical: 'bottom',
              },
              PaperProps: {
                style: {
                  display: 'none',
                },
              },
            }}
            placeholder={t('location.distance')}
            renderValue={() => (!distance ? t('location.distance') : distance.name)}
            value={['']}></Select>
        )}
      />
      <StyledDistanceMenu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        PaperProps={{
          style: {
            width: anchorEl ? `${anchorEl.clientWidth}px` : 'auto',
            maxWidth: `${anchorEl?.clientWidth}px`,
          },
          sx: {
            transition: 'none !important',
          },
        }}>
        <MenuItem>
          <HeaderContainer>
            <H6>{t('location.distance')}</H6>
            <IconButton size='small' onClick={handleClose}>
              <CloseIcon color={'secondary'} data-testid='close-dropdown' />
            </IconButton>
          </HeaderContainer>
        </MenuItem>
        {distanceOptions.map((distanceOption, index) => (
          <MenuItem
            key={index}
            data-testid={'distance-' + index}
            sx={{ paddingLeft: `${ehiTheme.spacing(0.5)}` }}
            onClick={() => {
              setValue(BranchLookupFields.Distance, distanceOption);
            }}>
            <Radio checked={distanceOption.value === distance?.value} />
            <ListItemText primary={distanceOption.name} />
          </MenuItem>
        ))}

        <ButtonContainer>
          <EhiButton
            disabled={distance?.value === DistanceValues.TWENTY_FIVE}
            onClick={() => {
              resetField(BranchLookupFields.Distance);
              handleClose();
            }}>
            {t('common.clear')}
          </EhiButton>
        </ButtonContainer>
      </StyledDistanceMenu>
    </StyledFilterFormControl>
  );
};
