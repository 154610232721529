import { FC, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffectOnlyOnce } from 'hooks/useEffectOnlyonce';
import { RouterPaths } from 'app/router/RouterPaths';
import { FullScreenSpinner } from 'components/shared/ui/spinner/FullScreenSpinner';
import { LoadableView } from 'components/shared/ui/spinner/loadableView/LoadableView';
import { loadEhiLocationCookie } from '@ehi/location';
import { useEhiLocation } from 'hooks/useEhiLocation';
import { useLocationRollUpService } from 'services/location/useLocationRollUpService';
import { LoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewTypes';
import NetworkError from 'components/shared/errors/NetworkError';

type LocationGuardProps = {
  children: ReactNode;
};

export const LocationGuard: FC<LocationGuardProps> = ({ children }) => {
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.LOADING);
  const navigate = useNavigate();
  const { validateLocation } = useEhiLocation();
  const { queryLocation } = useLocationRollUpService();

  useEffectOnlyOnce(async () => {
    try {
      const cookie = loadEhiLocationCookie();
      if (cookie === undefined) {
        setLoadingState(LoadingState.SUCCESS);
        navigate(RouterPaths.SetLocation);
      } else {
        const cachedLocation = await queryLocation(cookie.peoplesoftId);

        await validateLocation(cachedLocation);
        setLoadingState(LoadingState.SUCCESS);
      }
    } catch (e) {
      setLoadingState(LoadingState.ERROR);
    }
  });

  return (
    <LoadableView loadingComponent={<FullScreenSpinner />} errorComponent={<NetworkError />} state={loadingState}>
      {children}
    </LoadableView>
  );
};
