import { RootState } from 'redux/store';
import {
  Charges,
  Contact,
  EditorPayers,
  DriverProfileRenter,
  Pickup,
  RateSource,
  Renter,
  Return,
  TransactionalProfileRenter,
  VehicleClassSelection,
  AdditionalDriver,
} from 'services/booking/bookingTypes';
import { getDaysDiff } from 'utils/dateUtils';
import { isOneWay } from 'utils/locationUtils';
import { isEmpty } from 'utils/stringUtils';

/**
 * Try to avoid using this one unless you really need a lot of the data from store at a time.
 * Instead, use other selectors
 */
export const selectReservationData = ({ bookingEditor }: RootState) => {
  return bookingEditor?.reservation;
};

export const selectBrand = ({ bookingEditor }: RootState): string | undefined => {
  return bookingEditor?.reservation?.brand;
};

export const selectCharges = ({ bookingEditor }: RootState): Charges | undefined => {
  return bookingEditor?.reservation?.charges;
};

export const selectRenter = ({ bookingEditor }: RootState): Renter | undefined => {
  return bookingEditor?.reservation?.renter;
};

export const selectDriverProfileRenter = ({ bookingEditor }: RootState): DriverProfileRenter | undefined => {
  return bookingEditor?.reservation?.renter as DriverProfileRenter;
};

export const selectTransactionalProfileRenter = ({
  bookingEditor,
}: RootState): TransactionalProfileRenter | undefined => {
  return bookingEditor?.reservation?.renter as TransactionalProfileRenter;
};

export const selectAdditionalDrivers = ({ bookingEditor }: RootState): AdditionalDriver[] | undefined => {
  return bookingEditor?.reservation?.additionalDrivers;
};

export const selectContact = ({ bookingEditor }: RootState): Contact | undefined => {
  return bookingEditor?.reservation?.contact;
};

export const selectPickup = ({ bookingEditor }: RootState): Pickup | undefined => {
  return bookingEditor?.reservation?.pickup;
};

export const selectReturn = ({ bookingEditor }: RootState): Return | undefined => {
  return bookingEditor?.reservation?.return;
};

export const selectRateSource = ({ bookingEditor }: RootState): RateSource | undefined => {
  return bookingEditor?.reservation?.rateSource;
};

export const selectVehicleClassSelection = ({ bookingEditor }: RootState): VehicleClassSelection | undefined => {
  return bookingEditor?.reservation?.vehicleClassSelection;
};

export const selectAddons = ({ bookingEditor }: RootState) => {
  return bookingEditor?.reservation?.addOns;
};

export const selectStatusUrn = ({ bookingEditor }: RootState): string | undefined => {
  return bookingEditor?.reservation?.status;
};

export const selectBookingEditorId = ({ bookingEditor }: RootState): string => {
  return bookingEditor?.editorId || '';
};

export const selectIsReadOnlyFlow = ({ bookingEditor }: RootState) => {
  return bookingEditor?.reservation?.dataType === 'Reservation';
};

export const selectIsCreateFlow = ({ bookingEditor }: RootState) => {
  return bookingEditor?.reservation?.reservation === undefined && bookingEditor?.reservation?.dataType === 'Editor';
};

export const selectLengthOfRental = ({ bookingEditor }: RootState): number => {
  return getDaysDiff(bookingEditor?.reservation?.pickup?.dateTime, bookingEditor?.reservation?.return?.dateTime);
};

export const selectIsBookingEditorDirty = ({ bookingEditor }: RootState) => {
  return bookingEditor?.isDirty;
};

export const selectHasLocationData = ({ bookingEditor }: RootState): boolean => {
  return !isEmpty(bookingEditor?.reservation?.pickup?.branch) && !isEmpty(bookingEditor?.reservation?.return?.branch);
};

export const selectHasDatetimeData = ({ bookingEditor }: RootState): boolean => {
  return (
    !isEmpty(bookingEditor?.reservation?.pickup?.dateTime) && !isEmpty(bookingEditor?.reservation?.return?.dateTime)
  );
};

export const selectOneWay = ({ bookingEditor }: RootState): boolean => {
  return isOneWay(bookingEditor?.reservation?.pickup?.branch, bookingEditor?.reservation?.return?.branch);
};

export const selectPayers = ({ bookingEditor }: RootState): EditorPayers | undefined => {
  return bookingEditor?.reservation?.payers;
};
