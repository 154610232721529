import { RouterPaths } from 'app/router/RouterPaths';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useTranslations } from 'components/shared/i18n';
import { useRefreshEditor } from 'hooks/bookingEditor/useRefreshEditor';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { selectAddons, selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';
import { ServiceResultType } from 'services/types/ServiceResultTypes';
import { updateEquipment } from 'services/booking/bookingService';

import {
  EquipmentFormModel,
  EquipmentFormFields,
  EquipmentItemFields,
  BaseEquipment,
} from 'components/flexFlow/peo/peoTypes';
import { BLOCK_EQUIPMENT_CODES, equipmentInitialValues, formattedEquipmentTypes } from 'utils/peoUtils';
import { Equipment } from 'services/booking/bookingTypes';
import { parseUrn } from 'utils/urnUtils';

export type SaveEquipment = {
  saveEquipmentAndRefreshEditor: (equipmentModal: EquipmentFormModel) => Promise<ServiceResultType>;
  equipmentValues: EquipmentFormModel;
};

export const useSaveEquipment = (): SaveEquipment => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const addOns = useAppSelector(selectAddons);
  const { showAlert } = useAlert();
  const { refreshEditor } = useRefreshEditor();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();

  const equipmentValues: EquipmentFormModel = useMemo(() => {
    const selectedEquipments = formattedEquipmentTypes(addOns?.equipment?.selected);
    const types = selectedEquipments.map((item: BaseEquipment) => {
      return item.type;
    });
    const unBlockedEquipments = addOns?.equipment?.available?.filter((item) => {
      const equipmentCode = parseUrn(item.type);
      return !BLOCK_EQUIPMENT_CODES.includes(equipmentCode);
    });

    const available = unBlockedEquipments?.filter((item: Equipment) => {
      if (item.type) return !types.includes(item.type);
    });
    const availableEquipments = formattedEquipmentTypes(available);

    return equipmentInitialValues(selectedEquipments, availableEquipments);
  }, [addOns?.equipment]);

  const handleErrors = useCallback(
    (errors: ResponseMessage[]) => {
      showAlert({
        title: t('error.error'),
        description: t('error.editorError', {
          error: errors?.[0].localizedMessage || '',
        }),
        primaryActionText: t('error.reload'),
        secondaryActionText: t('error.home'),
        open: true,
        onPrimaryAction: () => {
          return refreshEditor(bookingEditorId);
        },
        onSecondaryAction: () => {
          navigate(RouterPaths.Search);
        },
        onClose: () => {
          return;
        },
      });
    },
    [bookingEditorId, navigate, refreshEditor, showAlert, t]
  );

  const saveEquipmentAndRefreshEditor = async (equipmentModal: EquipmentFormModel) => {
    const equipmentUpdates = equipmentModal[EquipmentFormFields.Items]
      ?.filter((protectionItemModel) => protectionItemModel[EquipmentItemFields.Checked])
      ?.map((protectionItemModel) => {
        const item = protectionItemModel[EquipmentItemFields.Item];
        const quantity = protectionItemModel[EquipmentItemFields.Quantity];
        return Array.from(Array(quantity)).map(() => {
          return { type: item.type };
        });
      })
      .flat();

    const { errors } = await updateAndRefresh(async () => {
      await updateEquipment(bookingEditorId, equipmentUpdates);
    });
    if (errors) {
      handleErrors(errors);
    }
    return { success: !!errors, errors: errors };
  };

  return {
    saveEquipmentAndRefreshEditor,
    equipmentValues,
  };
};
