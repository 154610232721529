import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexFlowTypes';
import { FlexFlowCard, FlexiFlowCardInnerContainer } from 'components/shared/ui/card/Card';
import { StickyCardNavigation } from 'components/shared/ui/card/StickyCardNavigation';
import CardContent from '@mui/material/CardContent';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { Body1 } from '@ehi/ui';
import { useTranslations } from 'components/shared/i18n';
import { mapLoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewUtils';
import { selectIsReadOnlyFlow, selectPayers } from 'redux/selectors/bookingEditor';
import { useAppSelector } from 'redux/hooks';
import { parseUrn } from 'utils/urnUtils';
import { AutoCompleteField, AutoCompleteOption } from 'components/shared/forms/AutoCompleteField';
import { useLocationReferencePaymentTypes } from 'services/location/locationQueries';
import { useMemo, useState } from 'react';
import { SavingFormProvider } from 'context/saveOnNavigate/SavingFormProvider';
import { useForm } from 'react-hook-form';
import { PaymentFields, PaymentValues } from 'components/flexFlow/payment/PaymentTypes';
import { useSavePayment } from 'components/flexFlow/payment/useSavePayment';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';

export const Payment: FlexiFunctionComponent = ({ onNext, onPrevious }) => {
  const { t } = useTranslations();
  const { save } = useSavePayment();
  const readOnly = useAppSelector(selectIsReadOnlyFlow);
  const payers = useAppSelector(selectPayers);
  const [loading, setLoading] = useState(false);

  const { data: referencePaymentTypes, isFetching: isFetchingPaymentTypes } = useLocationReferencePaymentTypes();

  const loadPaymentDropdown = useMemo(() => {
    const optionsArray: AutoCompleteOption[] = [];
    if (referencePaymentTypes) {
      for (const paymentType of referencePaymentTypes) {
        optionsArray.push({
          title: paymentType.code ? paymentType.code : '',
          key: paymentType.urn ? paymentType.urn : '',
        });
      }
    }
    return optionsArray;
  }, [referencePaymentTypes]);

  const formMethods = useForm<PaymentValues>({
    defaultValues: {
      paymentType: { title: parseUrn(payers?.person?.paymentMethod), key: payers?.person?.paymentMethod },
    },
    resolver: undefined,
  });

  const getSelectedValue = () => {
    const returnValue = loadPaymentDropdown.find(
      (value) => value?.title === formMethods.watch(PaymentFields.PaymentType)?.title
    );
    if (returnValue === undefined) {
      return { title: '', key: '' };
    }

    return returnValue;
  };

  const handleSubmit = async (values: PaymentValues) => {
    setLoading(true);
    return save(values).finally(() => {
      setLoading(false);
    });
  };

  const handleOnChange = (value: AutoCompleteOption) => {
    formMethods.setValue(PaymentFields.PaymentType, value, { shouldDirty: true });
  };

  const isLoading: boolean = isFetchingPaymentTypes || loading;

  return (
    <FlexFlowCard loadingState={mapLoadingState(false, false)}>
      <StickyCardNavigation onPrevious={onPrevious} onNext={onNext} showExitToRentalSummary={true} />
      <SavingFormProvider {...formMethods} submitFn={(values: PaymentValues) => handleSubmit(values)}>
        <CardContent>
          <FlexiFlowCardInnerContainer title={t('common.renter')}>
            {readOnly ? (
              <>
                <Caption2>{t('payment.paymentMethod')}</Caption2>
                <Body1>
                  {parseUrn(payers?.person?.paymentMethod) ? parseUrn(payers?.person?.paymentMethod) : '--'}
                </Body1>
              </>
            ) : (
              <AutoCompleteField
                name={PaymentFields.PaymentType}
                label={t('payment.paymentMethod')}
                data-testid='payment-auto-complete'
                value={getSelectedValue()}
                options={loadPaymentDropdown}
                handleOnChange={handleOnChange}
              />
            )}
            <ProgressOverlay inProgress={isLoading} />
          </FlexiFlowCardInnerContainer>
        </CardContent>
      </SavingFormProvider>
    </FlexFlowCard>
  );
};
