import React, { useCallback, useMemo, useState } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import { IconButton, Menu, Typography } from '@mui/material';

import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { BranchLookupFields } from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupTypes';
import {
  ClearButton,
  ClearButtonContainer,
  ContainerBox,
  MenuItemStyled,
  SelectStyled,
  StyledBrandFormControl,
  StyledInputLabel,
  TitleTypography,
} from 'components/flexFlow/whenAndWhere/branchLookup/brandFilter/BrandFilterDropDown.styles';
import { SelectedBrandsDisplay } from 'components/flexFlow/whenAndWhere/branchLookup/brandFilter/SelectedBrandsDisplay';
import { useTranslations } from 'components/shared/i18n';
import { useRentalBrandsQuery } from 'services/location/locationQueries';
import { Body1, ehiTheme } from '@ehi/ui';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';

const BrandFilterDropDown: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslations();
  const { control, setValue } = useFormContext();
  const { data } = useRentalBrandsQuery();
  const brands = useMemo(() => {
    if (data) {
      return data.map(({ code }: { code: string }) => code);
    }
    return [];
  }, [data]);

  const selectedBrands = useWatch({
    control,
    name: BranchLookupFields.Brands,
    defaultValue: brands,
  });

  const allBrandsSelected = selectedBrands.length === brands.length;

  const handleChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      setValue(BranchLookupFields.Brands, event.target.value as string[]);
    },
    [setValue]
  );

  const toggleAllBrands = useCallback(() => {
    const newSelectedBrands = allBrandsSelected ? [] : brands;
    setValue(BranchLookupFields.Brands, newSelectedBrands);
  }, [allBrandsSelected, brands, setValue]);

  const toggleBrandSelection = (
    selectedBrands: string[],
    brand: string,
    handleChange: (event: SelectChangeEvent<string[]>) => void
  ) => {
    const currentValue = selectedBrands.includes(brand);
    const newValue = currentValue
      ? selectedBrands.filter((item: string) => item !== brand)
      : [...selectedBrands, brand];

    handleChange({
      target: {
        value: newValue,
      },
    } as SelectChangeEvent<string[]>);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  const handleClear = () => {
    setValue(BranchLookupFields.Brands, brands);
    handleClose();
  };
  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  }, []);

  return (
    <StyledBrandFormControl variant='outlined' size='small'>
      <StyledInputLabel shrink>{t('location.brands')}</StyledInputLabel>
      <Controller
        name={BranchLookupFields.Brands}
        control={control}
        render={({ field }) => (
          <>
            <SelectStyled
              anchorEl={anchorEl}
              {...field}
              multiple
              value={selectedBrands}
              onChange={handleChange}
              renderValue={(value) => <SelectedBrandsDisplay value={value as string[]} brands={brands} />}
              onClick={handleClick}
              displayEmpty
              open={isOpen}
              data-testid={BranchLookupFields.Brands}
              MenuProps={{
                PaperProps: {
                  style: {
                    display: 'none',
                  },
                },
              }}
            />
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              slotProps={{
                paper: {
                  style: {
                    width: anchorEl ? `${anchorEl.clientWidth * 1.25}px` : 'auto',
                    ...(window.innerWidth <= 800 && {
                      minWidth: '25%',
                      paddingRight: '0px',
                    }),
                  },
                  sx: {
                    transition: 'none !important',
                  },
                },
              }}>
              <ContainerBox display='flex' justifyContent='space-between' alignItems='center'>
                <TitleTypography variant='subtitle1'>{t('location.brands')}</TitleTypography>
                <IconButton onClick={handleClose}>
                  <CloseIcon color='secondary' data-testid='close-dropdown' />
                </IconButton>
              </ContainerBox>
              <MenuItemStyled>
                <Body1 paddingLeft={ehiTheme.spacing(2)}>{t('whenWhere.allBrands')}</Body1>
                <Switch data-testid='toggleSwitch' checked={allBrandsSelected} onChange={toggleAllBrands} />
              </MenuItemStyled>
              {brands.map((brand: string) => (
                <MenuItem
                  key={brand}
                  value={brand}
                  style={{ padding: ehiTheme.spacing(1) }}
                  data-testid={`menu-item-${brand.toLowerCase()}`}
                  onClick={() => toggleBrandSelection(selectedBrands, brand, handleChange)}>
                  <Checkbox checked={selectedBrands.includes(brand)} />
                  <ListItemText primary={<Typography variant='subtitle1'>{brand}</Typography>} />
                </MenuItem>
              ))}
              <ClearButtonContainer display='flex' justifyContent='flex-end'>
                <ClearButton
                  onClick={handleClear}
                  sx={{
                    color: allBrandsSelected ? ehiTheme.palette.grey[500] : ehiTheme.palette.primary.main,
                  }}>
                  {t('common.clear')}
                </ClearButton>
              </ClearButtonContainer>
            </Menu>
          </>
        )}
      />
    </StyledBrandFormControl>
  );
};

export default BrandFilterDropDown;
