import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { useCallback } from 'react';
import { retrieveEditor } from 'services/booking/bookingService';
import { setReservation } from 'redux/slices/booking/bookingEditorSlice';
import { safelyCatchError } from 'utils/errorUtils';
import { ReservationEditor } from 'services/booking/bookingTypes';
import { ServiceResultType } from 'services/types/ServiceResultTypes';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useTranslations } from 'components/shared/i18n';
import { RouterPaths } from 'app/router/RouterPaths';
import { useNavigate } from 'react-router-dom';

export type RefreshEditorHook = {
  /**
   *  Retrieves Reservation Editor based on current session editorId, and updates redux with the updated editor.
   *   (returning service result so save and navigate works appropriately, don't want to continue to navigate on errors)
   *
   *  @return {Promise<ServiceResultType<ReservationEditor>>}
   */
  refreshEditor: (editorId?: string) => Promise<ServiceResultType<ReservationEditor>>;
};

export const useRefreshEditor = (): RefreshEditorHook => {
  const { t } = useTranslations();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const dispatch = useAppDispatch();
  const { showAlert } = useAlert();
  const navigate = useNavigate();

  const refreshEditor = useCallback(
    async (editorId = bookingEditorId) => {
      let editor;
      let errors;
      try {
        editor = await retrieveEditor(editorId);
      } catch (error) {
        const ehiErrorsResponse = safelyCatchError(error);
        errors = ehiErrorsResponse.errors;

        showAlert({
          title: t('error.error'),
          description: t('error.editorError', {
            error: errors?.[0].localizedMessage || '',
          }),
          primaryActionText: t('error.reload'),
          secondaryActionText: t('error.home'),
          open: true,
          onPrimaryAction: () => {
            return refreshEditor(editorId);
          },
          onSecondaryAction: () => {
            navigate(RouterPaths.Search);
          },
          onClose: () => {
            return;
          },
        });
      }

      if (editor) {
        dispatch(setReservation({ editor: editor }));
      }

      return { data: editor, success: !!editor, errors };
    },
    [bookingEditorId, dispatch, navigate, showAlert, t]
  );

  return { refreshEditor };
};
