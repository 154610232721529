import { FC, Fragment } from 'react';
import { Body2, ehiTheme, Subtitle1 } from '@ehi/ui';
import { useTranslations } from 'components/shared/i18n';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { Box } from '@mui/material';
import { RenterWarningDivider } from 'components/shared/ui/styles/Divider.styles';
import { piiField } from '@ehi/support';
import { parseUrn } from 'utils/urnUtils';
import { ReferenceDocument } from 'services/renter/driverProfile/driverProfileTypes';
import { toDateTimeString } from 'utils/dateUtils';
import { useCountriesQuery, useSubdivisionsQuery } from 'services/location/locationQueries';
import { getCountry, getSubdivision } from 'utils/countryUtils';
import { DriverWarningData } from './RenterWarningTypes';

type DriverWarningDetailsProps = {
  driverWarningData?: DriverWarningData;
};

export const DriverWarningDetails: FC<DriverWarningDetailsProps> = ({
  driverWarningData,
}: DriverWarningDetailsProps) => {
  const { t } = useTranslations();
  const { data: countries } = useCountriesQuery();
  const { data: subDivisions } = useSubdivisionsQuery(parseUrn(driverWarningData?.legalIdentification?.country) ?? '');

  return (
    <>
      {/* RENTER INFORMATION */}
      <Subtitle1 bold>{t('driver.renterInformation')}</Subtitle1>
      <GridContainer className={piiField} style={{ marginTop: '0.5rem' }} data-testid={'renterInformation'}>
        <LabelAndData
          label={t('driver.address')}
          data={
            <Box data-testid={'address-container'}>
              <Body2 bold display='block'>
                {driverWarningData?.address?.lines}
              </Body2>
              <Body2 bold>{driverWarningData?.address?.cityStateZip}</Body2>
            </Box>
          }
        />
        <LabelAndData
          label={t('driver.contact')}
          data={
            <Box data-testid={'address-contact'}>
              <Body2 bold>
                {t('driverSearch.contactType')} {driverWarningData?.phone}
              </Body2>
              <Body2 bold>{driverWarningData?.email}</Body2>
            </Box>
          }
        />
      </GridContainer>
      <RenterWarningDivider />

      {/* LICENSE INFORMATION */}
      <Subtitle1 bold>{t('driver.license')}</Subtitle1>
      <GridContainer className={piiField} style={{ marginTop: '0.5rem' }} data-testid={'renterLicense'}>
        <LabelAndData label={t('driver.driverLicenseNum')} data={driverWarningData?.legalIdentification?.number} />
        <LabelAndData
          label={t('driver.issuingCountry')}
          data={getCountry(countries, parseUrn(driverWarningData?.legalIdentification?.country))?.name}
        />
        <LabelAndData
          label={t('driver.issuingState')}
          data={
            getSubdivision(subDivisions, parseUrn(driverWarningData?.legalIdentification?.countrySubdivision))?.name
          }
        />
        <LabelAndData
          label={t('driver.issuingDate')}
          data={toDateTimeString(driverWarningData?.legalIdentification?.issueDate, t('format.date'))}
        />
        <LabelAndData
          label={t('driver.expirationDate')}
          data={toDateTimeString(driverWarningData?.legalIdentification?.expirationDate, t('format.date'))}
        />
        <LabelAndData label={t('driver.dateOfBirth')} data={driverWarningData?.dob} />
      </GridContainer>

      <RenterWarningDivider />

      {/* RENTER WARNING INFORMATION */}
      <Subtitle1 bold>{t('driver.warnings.renterWarningTitle')}</Subtitle1>
      <GridContainer style={{ marginTop: '0.5rem' }} data-testid={'renterWarningInfo'}>
        {driverWarningData?.referenceDocuments?.map((referenceDocument: ReferenceDocument, index) => (
          <Fragment key={index}>
            <LabelAndData
              label={t('driver.warnings.refDocNumber')}
              data={referenceDocument.number}
              data-testid={'refDocNumber'}
            />
            <LabelAndData
              label={t('location.groupBranch')}
              data={parseUrn(referenceDocument.branch)}
              data-testid={'groupBranch'}
            />
            <LabelAndData
              label={t('driver.warnings.amountOwed')}
              data={referenceDocument.amountOwed}
              data-testid={'amountOwed'}
            />
          </Fragment>
        ))}

        <LabelAndData
          label={t('driver.warnings.reasonDesc')}
          data={driverWarningData?.reason}
          data-testid={'reasonDesc'}
        />
        <LabelAndData
          label={t('driver.warnings.departmentDescription')}
          data={driverWarningData?.department.description}
          data-testid={'departmentDescription'}
        />
        <LabelAndData
          label={t('driver.warnings.message')}
          data-testid={'warningMessage'}
          data={driverWarningData?.message}
          fullWidth
        />
        <LabelAndData
          label={t('driver.contact')}
          data-testid={'departmentContact'}
          data={
            <Box>
              <Body2 bold>{driverWarningData?.department.phoneNumber}</Body2>
            </Box>
          }
        />
        <LabelAndData
          label={t('location.region')}
          data-testid={'region'}
          data={parseUrn(driverWarningData?.department.region)}
        />
        <LabelAndData
          label={t('location.group')}
          data-testid={'group'}
          data={parseUrn(driverWarningData?.department?.group)}
        />
        <LabelAndData
          label={t('driver.warnings.dateReported')}
          data-testid={'dateReported'}
          data={driverWarningData?.reportDate}
        />
      </GridContainer>
      <RenterWarningDivider />
    </>
  );
};

const LabelAndData = ({
  label,
  data,
  fullWidth,
}: {
  label: string;
  data?: string | JSX.Element | null;
  fullWidth?: boolean;
}) => {
  return (
    <GridItem {...(fullWidth && { sm: 12 })} style={{ padding: ehiTheme.spacing(0.5, 0.5, 0.5, 0) }}>
      <Caption2 display='block'>{label}</Caption2>
      {typeof data === 'string' ? (
        <Body2 bold display='block'>
          {data || ''}
        </Body2>
      ) : (
        data
      )}
    </GridItem>
  );
};
