import { FieldValues, useFormContext } from 'react-hook-form';
import { ServiceResultType } from 'services/types/ServiceResultTypes';
import { useSaveOnNavigateContext } from 'context/saveOnNavigate/SaveOnNavigateContext';

/**
 * @param submitFn function to called on valid form submit
 * @param alwaysBlock block navigation until validation is successful, even if there were no changes to the form
 *
 * @see also SavingFormProvider
 */
export const useFormSaveOnNavigate = <TValues extends FieldValues>(
  submitFn: (values: TValues) => Promise<ServiceResultType>,
  alwaysBlock?: boolean
): void => {
  const { registerSaveAction } = useSaveOnNavigateContext();
  const { formState, handleSubmit } = useFormContext<TValues>();

  const onSave = () =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise<ServiceResultType>(async (resolve, reject) => {
      let result: ServiceResultType | undefined = undefined;
      await handleSubmit(async (values) => {
        result = await submitFn(values);
      })();

      if (result) {
        resolve(result);
      } else {
        reject(formState.errors);
      }
    });

  registerSaveAction({
    onSave: onSave,
    when: alwaysBlock || formState.isDirty,
  });
};
