import { useAppSelector } from 'redux/hooks';
import {
  selectBookingEditorId,
  selectHasDatetimeData,
  selectHasLocationData,
  selectIsReadOnlyFlow,
  selectPickup,
  selectReturn,
} from 'redux/selectors/bookingEditor';
import { parseUrn } from 'utils/urnUtils';
import { UseVehicleRatesReturn } from 'services/booking/vehicleRateTypes';
import { useVehicleRatesQuery } from 'services/booking/bookingQueries';

export const useVehicleRates = (): UseVehicleRatesReturn => {
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const pickupData = useAppSelector(selectPickup);
  const returnData = useAppSelector(selectReturn);
  const hasLocationData = useAppSelector(selectHasLocationData);
  const hasDatetimeData = useAppSelector(selectHasDatetimeData);
  const isResViewOnly = useAppSelector(selectIsReadOnlyFlow);

  const vehicleRatesKey = `${bookingEditorId}-${parseUrn(
    pickupData?.branch
  )}-${pickupData?.dateTime?.trim()}-${parseUrn(returnData?.branch)}-${returnData?.dateTime?.trim()}`;
  const enableApi = !!bookingEditorId && !isResViewOnly && hasLocationData && hasDatetimeData;

  const { data, isLoading, error } = useVehicleRatesQuery(bookingEditorId, vehicleRatesKey, {
    enabled: enableApi,
  });

  return {
    vehicleRates: data?.vehicleRates,
    isLoading: enableApi && isLoading,
    errors: error,
  };
};
