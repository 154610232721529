import { useState } from 'react';
import { FlexFlowCard, FlexiFlowCardInnerContainer } from 'components/shared/ui/card/Card';
import { StickyCardNavigation } from 'components/shared/ui/card/StickyCardNavigation';
import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexFlowTypes';
import { CardActions } from '@mui/material';
import { ehiTheme } from '@ehi/ui';
import { usePhoneTypesQuery } from 'services/renter/renterReferenceQueries';
import { useTranslations } from 'components/shared/i18n';
import { useGetRenterProfile } from 'services/renter/useGetRenterProfile';
import { useAppSelector } from 'redux/hooks';
import { selectIsReadOnlyFlow, selectRenter } from 'redux/selectors/bookingEditor';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AddDriverDialog } from 'components/flexFlow/driver/AddDriverDialog';
import { QuickDriverDialog } from 'components/flexFlow/driver/additionalDriver/QuickDriverDialog';
import { StyledButtonGroup, StyledEhiButton } from 'components/flexFlow/driver/Driver.styles';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import NetworkError from 'components/shared/errors/NetworkError';
import { Action } from 'components/shared/ui/card/CardTypes';
import { useGetAdditionalDriverProfiles } from 'services/renter/useGetAdditionalDriverProfiles';
import { DriverCard } from 'components/flexFlow/driver/DriverCard';

export const Driver: FlexiFunctionComponent = ({ onNext, onPrevious }) => {
  const { t } = useTranslations();
  const isReadOnlyFlow = useAppSelector(selectIsReadOnlyFlow);
  const primaryDriver = useAppSelector(selectRenter);
  const [addDriverOpen, setAddDriverOpen] = useState(false);
  const [quickAddDriverOpen, setQuickAddDriverOpen] = useState(false);
  const { driverData, isRenterProfileLoading, isRenterProfileError } = useGetRenterProfile();

  const {
    data: additionalDriverProfiles,
    additionalDriversFetching,
    additionalDriversErrors,
  } = useGetAdditionalDriverProfiles();

  const { data: phoneTypeDomain, isFetching: isPhoneDomainLoading, isError: isPhoneDomainError } = usePhoneTypesQuery();
  const headerActions: Action[] = [
    {
      label: t('driverSearch.add'),
      testId: 'add-driver',
      onClick: () => setAddDriverOpen(true),
      color: 'primary',
    },
  ];

  const handleClick = () => {
    setAddDriverOpen(true);
  };

  const handleToggle = () => {
    setQuickAddDriverOpen(true);
  };

  return (
    <FlexFlowCard data-testid={'driverFlexFlowCard'}>
      <StickyCardNavigation onPrevious={onPrevious} onNext={onNext} showExitToRentalSummary={true} />
      <FlexiFlowCardInnerContainer
        sx={{ marginRight: ehiTheme.spacing(4), marginLeft: ehiTheme.spacing(4) }}
        title={t('driver.primaryDriver')}
        data-testid={`primary-driver`}
        headerActions={!(driverData && driverData?.lastName) ? headerActions : []}>
        {driverData && <DriverCard driver={driverData} phoneTypeDomain={phoneTypeDomain} />}
      </FlexiFlowCardInnerContainer>
      {additionalDriverProfiles?.map((driverData, index) => (
        <FlexiFlowCardInnerContainer
          key={`additional-driver-${index}`}
          data-testid={`additional-driver-${index}`}
          title={t('driver.additionalDrivers.additionalDrivers')}
          headerActions={[
            {
              label: t('common.edit'),
              onClick: () => {
                console.log('Edit functionality');
              },
              testId: 'edit-driver',
              color: 'primary',
            },
          ]}
          sx={{ margin: ehiTheme.spacing(2, 4, 0, 4) }}>
          <DriverCard driver={driverData} phoneTypeDomain={phoneTypeDomain} isAdditionalDriver />
        </FlexiFlowCardInnerContainer>
      ))}
      {!isReadOnlyFlow && driverData && driverData?.lastName && additionalDriverProfiles?.length < 9 && (
        <FlexiFlowCardInnerContainer sx={{ border: 'none', marginTop: 0 }}>
          <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div>
              <StyledButtonGroup variant='contained' aria-label='split button'>
                <StyledEhiButton onClick={handleClick} primary data-testid='add-driver-button'>
                  <span>{t('driver.addDriverTitle')}</span>
                </StyledEhiButton>
                <StyledEhiButton
                  size='small'
                  aria-label='quick add driver'
                  aria-haspopup='menu'
                  onClick={handleToggle}
                  primary
                  data-testid='quick-add-driver-button'>
                  <ArrowDropDownIcon />
                </StyledEhiButton>
              </StyledButtonGroup>
            </div>
          </CardActions>
        </FlexiFlowCardInnerContainer>
      )}
      {addDriverOpen && (
        <AddDriverDialog
          open={addDriverOpen}
          onClose={() => setAddDriverOpen(false)}
          title={primaryDriver ? t('driver.additionalDrivers.title') : t('driver.addPrimaryDriver')}
          data-testid='add-driver-dialog'
        />
      )}
      {quickAddDriverOpen && (
        <QuickDriverDialog
          open={quickAddDriverOpen}
          onClose={() => setQuickAddDriverOpen(false)}
          driverCount={additionalDriverProfiles.length}
          data-testid='quick-add-driver-dialog'
        />
      )}
      <ProgressOverlay inProgress={isRenterProfileLoading || isPhoneDomainLoading || additionalDriversFetching} />
      {(isRenterProfileError || isPhoneDomainError || additionalDriversErrors) && <NetworkError />}
    </FlexFlowCard>
  );
};
