import { CardContent, Grid } from '@mui/material';
import { ReservationSummaryCard } from 'components/resSummary/ReservationSummaryCard';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { useTranslations } from 'components/shared/i18n';
import { Body1, Body2 } from '@ehi/ui';
import { useAccountDetails } from 'services/businessAccount/useAccountDetails';
import { parseUrn } from 'utils/urnUtils';
import { RouterPaths } from 'app/router/RouterPaths';
import { mapLoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewUtils';

export const RateSourceAndBillToSummary: React.FC = (): JSX.Element => {
  const { t } = useTranslations();
  const { accountDetails, isLoading, hasRetailRatePlan, errors } = useAccountDetails();

  const renderContent = (): JSX.Element => {
    if (accountDetails) {
      return (
        <>
          <Grid item xs data-testid='rateSourceDetails'>
            <Caption2>{t('rateAndBilling.rateSource')}</Caption2>
            <Body1 data-testid='rateSourceAccountName'>{accountDetails.name}</Body1>
            <Body2 data-testid='rateSourceAccountType'>{parseUrn(accountDetails.type)}</Body2>
          </Grid>
          <Grid item xs data-testid='billToDetails'>
            <Caption2>{t('rateAndBilling.billTo')}</Caption2>
            {/* Additional bill-to details can be rendered here */}
          </Grid>
        </>
      );
    } else if (hasRetailRatePlan) {
      return (
        <Grid item xs data-testid='retailRatePlan'>
          <Body2>{t('rateAndBilling.retail')}</Body2>
        </Grid>
      );
    } else {
      return (
        <Grid item xs data-testid='noRateSourceSelected'>
          <Body2>{t('rateAndBilling.noRateSourceSelected')}</Body2>
        </Grid>
      );
    }
  };

  return (
    <ReservationSummaryCard
      title={t('reservationSummary.rateSource')}
      loading={mapLoadingState(isLoading, !!errors)}
      path={RouterPaths.RateAndBill}
      errors={[...(errors?.errors || [])]}
      data-testid='rateSourceSummaryCard'>
      <CardContent data-testid='rateSourceSummaryContent'>
        <Grid container direction={'column'}>
          {renderContent()}
        </Grid>
      </CardContent>
    </ReservationSummaryCard>
  );
};
