import { ActionOptions, EhiAlertDialog } from '@ehi/ui';
import React from 'react';
import { AlertDialogProps } from './AlertDialogTypes';
import { DialogProps } from '@mui/material';
import { useTranslations } from 'components/shared/i18n';
import { useDialogMenuItems } from 'components/shared/ui/dialogs/useDialogMenuItems';
import { Menu } from 'components/shared/ui/moreMenu/Menu';

export const AlertDialog: React.FC<DialogProps & AlertDialogProps> = ({
  title,
  primaryActionText,
  secondaryActionText,
  open,
  onClose,
  description,
  moreMenu,
  onPrimaryAction,
  onSecondaryAction,
  variant = 'alert',
  collectErrorInformation,
}) => {
  const { t } = useTranslations();
  const moreMenuItems = useDialogMenuItems(onClose, collectErrorInformation);

  title =
    title || (variant === 'error' && t('error.error')) || (variant === 'warning' && t('error.warning')) || undefined;

  const actions: ActionOptions = {
    primaryAction: {
      label: primaryActionText || t('common.close'),
      type: variant === 'destructive' ? 'error' : 'standard',
      onClick: () => {
        onPrimaryAction && onPrimaryAction();
        onClose();
      },
    },
  };

  if (secondaryActionText) {
    actions.secondaryAction = {
      label: secondaryActionText,
      onClick: () => {
        onSecondaryAction && onSecondaryAction();
        onClose();
      },
    };
  }

  return (
    <EhiAlertDialog
      style={{ whiteSpace: 'pre-line' }}
      open={open}
      onClose={onClose}
      title={title}
      description={description}
      titleActions={moreMenu ? [moreMenu] : [<Menu menuItems={moreMenuItems} key={'more-menu'} />]}
      actions={actions}></EhiAlertDialog>
  );
};
