import { useFieldArray, useFormContext } from 'react-hook-form';
import { PeoListItem } from './PeoListItem';
import { getPeoTitle } from 'utils/peoUtils';
import { useProtectionTypesQuery } from 'services/rentalReference/rentalReferenceQueries';
import { ProtectionFormModel, ProtectionsFormFields, ProtectionsItemFields } from 'components/flexFlow/peo/peoTypes';

export const ProtectionsList = () => {
  const { data: protectionDetails = [] } = useProtectionTypesQuery();
  const formContext = useFormContext<ProtectionFormModel>();
  const { fields } = useFieldArray({ control: formContext.control, name: ProtectionsFormFields.Items });
  return (
    <>
      {fields.map((field, index) => {
        const item = field[ProtectionsItemFields.Item];
        const namePrefix = `${ProtectionsFormFields.Items}[${index}]`;
        const title = getPeoTitle(item.code, protectionDetails);

        return (
          <PeoListItem
            key={field.id}
            index={index}
            title={title}
            selectability={item.selectability ?? ''}
            code={item.code}
            rate={item.rate}
            checkBoxFieldName={`${namePrefix}.${ProtectionsItemFields.Checked}`}
          />
        );
      })}
    </>
  );
};
